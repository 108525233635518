import * as React from "react";
import UnstyledButton from "../../components/buttons/UnstyledButton";
import { RAW_cssValue } from "../../nessie/stylingLib";
import { RoundedIcon } from "./RoundedIcon";
import { ClassroomIcon } from "./iconsV2/output/";
import { NessieThemeColors } from "./theme";
import BodyText from "./typography/bodyText";
import MinutiaeText from "./typography/minutiaeText";

// TODO: Container tests are broken due to not knowing how to handle a png file, rivisit this and uncomment.
// import placeholderImage from "../assets/placeholder.png";

type ChipProps = {
  children: React.ReactNode;
  size?: "s" | "m";
  onClick?: () => void;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name": string;
};

export const Chip = ({ children, size = "m", onClick, "data-name": dataName }: ChipProps) => {
  const padding = "m";
  const height = size === "s" ? "36px" : "48px";

  const childWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement<{ size: string }>(child)) {
      return React.cloneElement(child, { size });
    }
  });
  const ButtonOrDivComponent = onClick ? UnstyledButton : "div";
  return (
    <ButtonOrDivComponent
      onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        border: "dt_card",
        borderRadius: "dt_radius_s",
        cursor: onClick ? "pointer" : "unset",
        height,
        padding,
        paddingRight: "dt_m",
        position: "relative",
      }}
      data-name={dataName}
    >
      {resolveIcon(childWithProps)}
      {resolveImage(childWithProps)}
      {resolveText(size, children)}
    </ButtonOrDivComponent>
  );
};

function resolveIcon(children: React.ReactElement[] | undefined | null) {
  let icon;
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && child.type === ChipIcon) {
      icon = child;
    }
  });
  return icon;
}

function resolveImage(children: React.ReactElement[] | undefined | null) {
  let image;
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && child.type === ChipImage) {
      image = child;
    }
  });
  return image;
}

function resolveText(size: "s" | "m", children: React.ReactNode) {
  let text;
  React.Children.forEach(children, (child) => {
    if (typeof child === "string") {
      text = child;
    }
  });
  return size === "s" ? <MinutiaeText>{text}</MinutiaeText> : <BodyText>{text}</BodyText>;
}

type ChipIconProps = {
  size?: "s" | "m";
  color?: NessieThemeColors;
  backgroundColor?: NessieThemeColors;
  icon?: React.ReactElement;
};
const defaultIcon = <ClassroomIcon color="inherit" size="s" />;

export const ChipIcon = ({
  size,
  color = "dt_content_primary",
  backgroundColor = "dt_background_primary",
  icon = defaultIcon,
}: ChipIconProps) => {
  return (
    <div sx={{ display: "flex", marginLeft: RAW_cssValue("-12px"), marginRight: "dt_s", position: "relative" }}>
      <RoundedIcon backgroundColor={backgroundColor} color={color} icon={icon} size={size} />
    </div>
  );
};
ChipIcon.displayName = "ChipIcon";

type ChipImageProps = {
  url: string;
  size?: "s" | "m";
};
export const ChipImage = ({ url, size }: ChipImageProps) => {
  let imageSize;
  size === "s" ? (imageSize = "24") : (imageSize = "36");
  return (
    <div
      sx={{
        backgroundColor: "dt_content_accent",
        backgroundImage: `url("${url}")`,
        backgroundPosition: "center",
        borderRadius: "dt_radius_s",
        backgroundSize: "cover",
        marginLeft: RAW_cssValue("-12px"),
        marginRight: "dt_s",
        width: `${imageSize}px`,
        height: `${imageSize}px`,
        position: "relative",
      }}
    />
  );
};
ChipImage.displayName = "ChipImage";
