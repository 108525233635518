import { useState, ComponentProps } from "react";
import { LabelFormElement } from "../..";
import { CheckboxEmptyIcon, CheckboxSelectedIcon, ChevronDownIcon } from "../../nessie/icons";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { ScrollContainer } from "./ScrollContainer";
import { textFieldStyles } from "./TextField";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

export type ComboBoxMultiSelectOptionType = { label: string; value: string; to?: string };
export interface ComboBoxMultiSelectProps<T extends Readonly<ComboBoxMultiSelectOptionType[]>> {
  options: T;
  labelText: string;
  labelPosition?: "top" | "hidden";
  selectedValues?: ComboBoxMultiSelectOptionType["value"][];
  onNewSelection?: (checked: boolean, option: T[number]) => void;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  translate?: "no" | "yes";
  ContentProps?: Omit<ComponentProps<typeof DropdownMenu.Content>, "position" | "sideOffset">;
  icon?: JSX.Element;
  portalContainer?: HTMLElement | null;
  contentSide?: "top" | "bottom" | "left" | "right";
}

export function ComboBoxMultiSelect<T extends Readonly<ComboBoxMultiSelectOptionType[]>>({
  options,
  labelText,
  labelPosition = "top",
  selectedValues,
  onNewSelection,
  className,
  placeholder,
  translate,
  ContentProps,
  icon,
  portalContainer,
  contentSide = "bottom",
}: ComboBoxMultiSelectProps<T>) {
  const [baseId] = useState(() => self.crypto.randomUUID());
  const [isOpen, setIsOpen] = useState(false);
  const handleSelection = (checked: boolean, value: string) => {
    const filteredOptions = options.filter((option) => value === option.value);
    const selectedOption = filteredOptions[0];
    if (onNewSelection) {
      return onNewSelection(checked, selectedOption);
    }
  };

  return (
    // the onKeyDown is needed to close the dropdown when the escape key is pressed when inside a modal.
    // radix and react modal seem to be conflicting with each other.
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      translate={translate}
      className={className}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "xs",
      }}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          e.stopPropagation();
          setIsOpen(false);
        }
      }}
    >
      <DropdownMenu.Root
        open={isOpen}
        onOpenChange={(e) => {
          if (e) setIsOpen(true);
          else setIsOpen(false);
        }}
      >
        {labelPosition === "hidden" ? null : <LabelFormElement htmlFor={baseId} labelText={labelText} />}

        <DropdownMenu.Trigger aria-label={labelText} id={baseId} sx={comboInputStyles}>
          <div
            sx={{
              color: "dt_content_primary",
            }}
          >
            {icon}
            {`${placeholder} ${selectedValues?.length ? `(${selectedValues.length})` : ""}`}
          </div>
          <ChevronDownIcon size="s" color="dt_content_tertiary" />
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal container={portalContainer}>
          <DropdownMenu.Content {...ContentProps} sideOffset={5} side={contentSide} align="start">
            <ScrollContainer
              maxHeight={300}
              sx={{
                borderRadius: "dt_radius_s",
                border: "dt_card",
                backgroundColor: "dt_background_primary",
              }}
            >
              <DropdownMenu.Group>
                {options?.map((option) => {
                  const isSelected = selectedValues?.includes(option.value);
                  return (
                    <DropdownMenu.CheckboxItem
                      key={option.value}
                      checked={isSelected}
                      onCheckedChange={(checked) =>
                        handleSelection(checked === "indeterminate" ? false : Boolean(checked), option.value)
                      }
                      sx={comboOptionStyles}
                    >
                      <div sx={{ height: 24, marginRight: "xs" }}>
                        {isSelected ? (
                          <CheckboxSelectedIcon size="m" color="dt_content_accent" />
                        ) : (
                          <CheckboxEmptyIcon size="m" color="dt_border_functional" />
                        )}
                      </div>
                      {option.label}
                    </DropdownMenu.CheckboxItem>
                  );
                })}
              </DropdownMenu.Group>
            </ScrollContainer>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
}

const comboInputStyles: ThemeUIStyleObject = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  backgroundColor: "dt_background_primary",
  ...textFieldStyles,
  "&[aria-expanded='true']": {
    borderWidth: "2px",
    borderColor: "dt_border_active",
  },
  "> div:nth-of-type(1)": {
    display: "flex",
    alignItems: "center",
    gap: "xs",
  },
  ":focus": {
    borderColor: "dt_border_active",
    outline: `none`,
  },
};

const comboOptionStyles: ThemeUIStyleObject = {
  padding: "dt_s",
  display: "flex",
  alignItems: "center",
  border: "dt_card",
  borderColor: "transparent",
  fontSize: "18px",
  lineHeight: "24px",
  color: "dt_content_primary",
  width: "100%",
  backgroundColor: "dt_background_primary",
  borderRadius: "dt_radius_s",
  userSelect: "none",

  ":hover": {
    backgroundColor: "dt_background_accent",
  },
};
