import * as React from "react";
import { T } from "../..";
import DetailSection from "./DetailSection";

type LearnMoreDetailProps = {
  isInUK?: boolean;
};

export default function LearnMoreDetail({ isInUK }: LearnMoreDetailProps): JSX.Element {
  return (
    <div>
      {LEARN_MORE_DETAIL_STRINGS(isInUK).map(({ subheading, detailText }, i) => (
        <DetailSection key={i} subheading={subheading} detailText={detailText} />
      ))}
    </div>
  );
}

const LEARN_MORE_DETAIL_STRINGS = (
  isInUK?: boolean,
): { subheading: React.ReactNode; detailText: React.ReactNode }[] => [
  {
    subheading: <T str="dojo.common:consent.why_am_i_seeing_this" fallback="Why am I seeing this?" />,
    detailText: (
      <T
        htmlStr="dojo.common:consent.why_am_i_seeing_this_html_v2"
        fallback="<div>We periodically do privacy refreshes to ensure we are serving you well. We ask for some personal information, like your name, when you use ClassDojo, and we care about your privacy. We want to make sure we always have your consent to provide you with ClassDojo’s service.</div>"
      />
    ),
  },
  {
    subheading: <T str="dojo.common:consent.what_you_need_to_know" fallback="Some of what you need to know" />,
    detailText: isInUK ? (
      <T
        htmlStr="dojo.common:consent.what_you_need_to_know_html_uk"
        fallback='<div><ul><li>ClassDojo is UK GDPR compliant</li><li>There have been <u><b>no</b></u> changes to how we protect your information, what we collect, and where it is stored. See the details on our <a href="https://www.classdojo.com/transparency/" target="_blank">Information Transparency page</a>.</li><li>ClassDojo <u><i>never</i></u> sells any of your personal information to advertisers or marketers. You can read more about this in our <a href="https://www.classdojo.com/privacy/" target="_blank">Privacy Policy</a>.</li><li>You are in control: with your consent, your information is stored in the US, where our team is based. You can <a href="https://www.classdojo.com/privacy/#object-restrict-or-withdraw-consent" target="_blank">withdraw your consent</a> at any time.</li></ul></div><div>Please note that the US may not have the same information protection laws as your country, including disclosure to law enforcement authorities.</div>'
      />
    ) : (
      <T
        htmlStr="dojo.common:consent.what_you_need_to_know_html"
        fallback='<div><ul><li>ClassDojo is GDPR compliant</li><li>There have been <u><b>no</b></u> changes to how we protect your information, what we collect, and where it is stored. See the details on our <a href="https://www.classdojo.com/transparency/" target="_blank">Information Transparency page</a>.</li><li>ClassDojo <u><i>never</i></u> sells any of your personal information to advertisers or marketers. You can read more about this in our <a href="https://www.classdojo.com/privacy/" target="_blank">Privacy Policy</a>.</li><li>You are in control: with your consent, your information is stored in the US, where our team is based. You can <a href="https://www.classdojo.com/privacy/#object-restrict-or-withdraw-consent" target="_blank">withdraw your consent</a> at any time.</li></ul></div><div>Please note that the US may not have the same information protection laws as your country, including disclosure to law enforcement authorities.</div>'
      />
    ),
  },
  {
    subheading: <T str="dojo.common:consent.youre_in_control" fallback="As always, you're in control" />,
    detailText: (
      <T
        htmlStr="dojo.common:consent.youre_in_control_details_html_v2"
        fallback='<div>You can <a href="https://www.classdojo.com/privacy/#object-restrict-or-withdraw-consent" target="_blank">withdraw or change your consent</a> at any time.</div><div>Visit our <a href="https://www.classdojo.com/privacycenter/" target="_blank">Privacy Center</a> for help, or write to our team at <a href="mailto:privacy@classdojo.com" target="_blank">privacy@classdojo.com.</a></div>'
      />
    ),
  },
];
