// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type PeopleIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let PeopleIcon: NessieFunctionComponent<PeopleIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "PeopleIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: PeopleIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.2 3.19995C15.0732 3.19995 14.1017 3.76134 13.5155 4.59892C15.1777 5.84368 16.0993 7.98996 15.6174 10.2158C15.5914 10.336 15.5642 10.4669 15.5353 10.6061L15.5353 10.6062L15.5352 10.6067C15.406 11.229 15.2428 12.0148 14.9985 12.7335C14.8002 13.317 14.5184 13.9581 14.0835 14.5504C14.3917 14.6649 14.7599 14.7859 15.1875 14.9251L15.2504 14.9455L15.2504 14.9455L15.2505 14.9455C15.8598 15.1437 16.6087 15.3873 17.2124 15.6741C17.6329 15.8738 18.0073 16.1561 18.3146 16.5H21.03C21.8419 16.5 22.5 15.8418 22.5 15.0299C22.5 14.4435 22.1567 13.9359 21.66 13.6999C21.2251 13.4933 20.6515 13.3066 20.0767 13.1196C18.9427 12.7506 17.8044 12.3801 17.7181 11.8521V11.3132C17.7181 11.1576 17.7218 10.972 17.8407 10.8762C18.7856 10.1148 19.0636 8.78086 19.3173 7.56408L19.3174 7.56382C19.3452 7.43017 19.3728 7.29793 19.4009 7.16802C19.8427 5.12741 18.2879 3.19995 16.2 3.19995ZM6.54186 9.83489C6.03694 7.50276 7.81386 5.29995 10.2 5.29995C12.5862 5.29995 14.3631 7.50276 13.8582 9.83489C13.826 9.98346 13.7945 10.1347 13.7626 10.2875C13.4727 11.6781 13.1549 13.2026 12.0751 14.0728C11.9391 14.1823 11.9349 14.3944 11.9349 14.5722V15.1881C12.0335 15.7916 13.3345 16.2149 14.6305 16.6367C15.2873 16.8505 15.9429 17.0638 16.44 17.2999C17.0076 17.5696 17.4 18.1498 17.4 18.8199C17.4 19.7478 16.6478 20.5 15.72 20.5H4.68C3.75216 20.5 3 19.7478 3 18.8199C3 18.1498 3.39241 17.5696 3.96 17.2999C4.44682 17.0687 5.08461 16.8583 5.72477 16.6471L5.72479 16.6471C7.01167 16.2226 8.30815 15.795 8.40724 15.1881V14.5495C8.40724 14.3569 8.38672 14.1371 8.2431 14.0126C7.23058 13.1349 6.92048 11.6465 6.6382 10.2915C6.60606 10.1372 6.57428 9.98461 6.54186 9.83489Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
PeopleIcon.nessiePropTypes = iconNessiePropTypes;
PeopleIcon = nessiePropCheckerWrapper(PeopleIcon);
export default PeopleIcon;
