// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type ChevronSmallDownIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let ChevronSmallDownIcon: NessieFunctionComponent<ChevronSmallDownIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "ChevronSmallDownIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: ChevronSmallDownIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M12.017 15.0093C11.7585 15.0113 11.4994 14.9137 11.3022 14.7165L7.30705 10.7213C6.91653 10.3308 6.91653 9.69764 7.30705 9.30711C7.69758 8.91659 8.33074 8.91659 8.72127 9.30711L12.017 12.6029L15.3127 9.30712C15.7033 8.91659 16.3364 8.91659 16.727 9.30712C17.1175 9.69764 17.1175 10.3308 16.727 10.7213L12.7318 14.7165C12.5346 14.9137 12.2755 15.0113 12.017 15.0093Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ChevronSmallDownIcon.nessiePropTypes = iconNessiePropTypes;
ChevronSmallDownIcon = nessiePropCheckerWrapper(ChevronSmallDownIcon);
export default ChevronSmallDownIcon;
