// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type MegaphoneIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let MegaphoneIcon: NessieFunctionComponent<MegaphoneIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "MegaphoneIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: MegaphoneIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          fill="currentColor"
          height="1em"
          viewBox="0 0 24 24"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="m18.3634 6.81787c-.1837.05625-.2887.25125-.2325.43875l1.0238 3.35248c.0562.1838.2512.2888.4387.2325 1.11-.3375 1.7363-1.51498 1.3988-2.62498s-1.515-1.73625-2.625-1.39875zm-.0937 9.94123c-.5288.39-1.2225.3488-1.6913-.045-1.7137-1.4475-3.8887-2.1637-6.075-2.0587-.285.015-.4837.285-.4012.5587l1.2112 3.9788c.2438.7987-.2587 1.6425-1.1062 1.7925-.61128.1087-1.21878-.24-1.50753-.7913l-2.22751-4.2487c-.0825-.1613-.25874-.2588-.43874-.2288-1.33125.24-2.66251-.57-3.03001-1.92-.37875-1.3987.50626-2.8312 1.89376-3.255l1.71375-.5212c2.8875-.88128 5.34748-3.06753 6.06748-5.99628.1388-.56625.6338-.99 1.2375-1.0275.5325-.03 1.0275.28125 1.2863.75 2.0062 3.645 3.2287 7.67248 3.5887 11.81998v.0488c0 .4425-.1762.885-.5287 1.1475z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
MegaphoneIcon.nessiePropTypes = iconNessiePropTypes;
MegaphoneIcon = nessiePropCheckerWrapper(MegaphoneIcon);
export default MegaphoneIcon;
