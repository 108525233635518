import { RAW_cssValue, ThemeUIStyleObject } from "../../../nessie/stylingLib";

const styles: Record<string, ThemeUIStyleObject> = {
  toggleImage: {
    height: "100%",
  },
  caret: {
    bottom: 5,
    right: 10,
    position: "absolute",
    width: 0,
    height: 0,
    border: RAW_cssValue("0.4rem solid transparent"),
    borderTop: RAW_cssValue(`0.4rem solid`),
    borderTopColor: "dt_content_tertiary",
  },

  // Picker
  iconItem: {
    cursor: "pointer",
    display: "inline-block",
    borderRadius: "dt_radius_s",
    padding: "dt_xs",
  },
  iconItemHovered: {
    backgroundColor: "dt_background_secondary",
  },
  iconImg: {
    width: "56px",
    height: "56px",
  },
};

export default styles;
