// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type MergeIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let MergeIcon: NessieFunctionComponent<MergeIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "MergeIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: MergeIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          {...props}
        >
          <path
            d="m17.517 11.457v-1.5783h-2.4552c-0.80612 0-1.3094 0.3234-1.7457 0.90188-0.49076 0.65056-0.84096 1.5521-1.2651 2.6534l-0.0098 0.02532c-0.3924 1.019-0.8566 2.2242-1.5894 3.1581-0.79328 1.0108-1.9165 1.7278-3.532 1.7278h-3.9016c-0.58448 0-1.0584-0.4738-1.0584-1.0583 0-0.58448 0.47384-1.0583 1.0583-1.0583h3.9016c0.87348 0 1.4144-0.34128 1.8669-0.91788 0.50684-0.64588 0.8684-1.5451 1.289-2.6374l0.04724-0.12276c0.3804-0.98932 0.82488-2.1451 1.5032-3.0445 0.76104-1.009 1.8494-1.7439 3.4355-1.7439h2.4552v-1.5783c0-0.43772 0.50112-0.68616 0.84952-0.42112l3.4652 2.6366c0.27836 0.21176 0.27836 0.63048 0 0.84224l-3.4652 2.6366c-0.3484 0.26508-0.84952 0.0166-0.84952-0.42112zm-7.6336-3.6908c0.53584 3.2e-4 1.042 0.56224 1.0423 1.0571 2.81e-4 0.49544-0.51988 1.0596-1.0423 1.0596-8.848 1.2e-4 -4.224-0.00272-6.8656-4e-3 -0.58448-3.2e-4 -1.0583-0.47108-1.0583-1.0556 0-0.58452 0.47392-1.0614 1.0584-1.0611z"
            clipRule="evenodd"
            fillRule="evenodd"
            strokeWidth={4}
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
MergeIcon.nessiePropTypes = iconNessiePropTypes;
MergeIcon = nessiePropCheckerWrapper(MergeIcon);
export default MergeIcon;
