// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type PlusIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let PlusIcon: NessieFunctionComponent<PlusIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "PlusIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: PlusIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.45344 2 2 6.49393 2 12C2 17.5466 6.49393 22 12 22C17.5466 22 22 17.5061 22 12C22 6.49393 17.5061 2 12 2ZM16.4939 13.1741H13.1741V16.4939C13.1741 17.1417 12.6478 17.668 12 17.668C11.3522 17.668 10.8259 17.1417 10.8259 16.4939V13.1741H7.50607C6.8583 13.1741 6.33198 12.6478 6.33198 12C6.33198 11.3522 6.8583 10.8259 7.50607 10.8259H10.8259V7.50607C10.8259 6.8583 11.3522 6.33198 12 6.33198C12.6478 6.33198 13.1741 6.8583 13.1741 7.50607V10.8259H16.4939C17.1417 10.8259 17.668 11.3522 17.668 12C17.668 12.6478 17.1417 13.1741 16.4939 13.1741Z"
          />
          <mask
            id="PlusIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={2}
            y={2}
            width={20}
            height={20}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 2C6.45344 2 2 6.49393 2 12C2 17.5466 6.49393 22 12 22C17.5466 22 22 17.5061 22 12C22 6.49393 17.5061 2 12 2ZM16.4939 13.1741H13.1741V16.4939C13.1741 17.1417 12.6478 17.668 12 17.668C11.3522 17.668 10.8259 17.1417 10.8259 16.4939V13.1741H7.50607C6.8583 13.1741 6.33198 12.6478 6.33198 12C6.33198 11.3522 6.8583 10.8259 7.50607 10.8259H10.8259V7.50607C10.8259 6.8583 11.3522 6.33198 12 6.33198C12.6478 6.33198 13.1741 6.8583 13.1741 7.50607V10.8259H16.4939C17.1417 10.8259 17.668 11.3522 17.668 12C17.668 12.6478 17.1417 13.1741 16.4939 13.1741Z"
            />
          </mask>
          <g mask="url(#PlusIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
PlusIcon.nessiePropTypes = iconNessiePropTypes;
PlusIcon = nessiePropCheckerWrapper(PlusIcon);
export default PlusIcon;
