// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type NotificationOffIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let NotificationOffIcon: NessieFunctionComponent<NotificationOffIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "NotificationOffIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: NotificationOffIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5804 7.41989L22.0001 3.00015L20.5859 1.58594L16.3928 5.77908C15.8669 5.32306 15.2199 4.95938 14.4636 4.65624C14.1921 4.54743 14 4.29266 14 4.00015C14 2.96521 13.1297 2.00015 11.9994 2.00015C10.8703 2.00015 9.99998 2.96521 9.99998 4.00015C9.99998 4.29199 9.8084 4.54622 9.53753 4.65483C7.28317 5.55876 5.99999 7.00015 5.99999 9.81917V13.5002V14.1375C5.99999 14.7757 5.48921 15.2759 4.88117 15.4696C3.87197 15.7911 3.29999 16.4655 3.29999 17.2631C3.29999 17.7421 3.4688 18.1329 3.75016 18.4217L0.585785 21.5861L2 23.0003L6.00015 19.0002H6.00046L17.5805 7.42011C17.5805 7.42004 17.5804 7.41996 17.5804 7.41989ZM18.7057 19.0002H8.82864L18 9.8288V13.5002V14.1376C18 14.7757 18.5107 15.2759 19.1187 15.4697C20.1275 15.7913 20.7031 16.4656 20.7031 17.2631C20.7031 18.3646 19.8071 19.0002 18.7057 19.0002ZM14 20.0002C14 21.1057 13.1055 22.0002 12 22.0002C10.8945 22.0002 9.99999 21.1057 9.99999 20.0002H14Z"
            fill="#7174A0"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
NotificationOffIcon.nessiePropTypes = iconNessiePropTypes;
NotificationOffIcon = nessiePropCheckerWrapper(NotificationOffIcon);
export default NotificationOffIcon;
