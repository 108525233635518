import { useState } from "react";
import { handleActionKeyDown } from "../../../utils/keyboard";
import styles from "./Styles";
import { UnstyledButton } from "../../buttons";

/**
 * Private.
 * Icon used internally by the Icon Picker
 */

type IconProps = {
  icon: { value: unknown; iconUrl: string };
  tabIndex?: number;
  onSelect: () => void;
  "aria-label"?: string;
};
const Icon = ({ icon, tabIndex = 0, onSelect, "aria-label": ariaLabel }: IconProps): JSX.Element => {
  const [hovered, setHovered] = useState(false);

  const iconItemStyles = {
    ...styles.iconItem,
    ...(hovered && styles.iconItemHovered),
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onKeyDown = handleActionKeyDown({ onAction: onSelect });

  return (
    <UnstyledButton
      data-name={`iconPickerItem-${icon.value}`}
      sx={iconItemStyles}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onSelect}
      tabIndex={tabIndex}
      // eslint-disable-next-line @web-monorepo/no-jsx-role-button
      role="button"
      onKeyDown={onKeyDown}
      aria-label={ariaLabel}
    >
      <img alt="" src={icon.iconUrl} sx={styles.iconImg} />
    </UnstyledButton>
  );
};

export default Icon;
