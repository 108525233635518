import { useEffect, useState, useCallback, useRef } from "react";
import * as React from "react";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";

function remToPx(rem: number) {
  return rem * 10;
}
const DEFAULT_WIDTH = "24rem";
const DEFAULT_GUTTER = 1.6;
const BOTTOM_SPACING = DEFAULT_GUTTER;

type SidebarProps = {
  sidebarComponents: React.ReactNode[];
  sidebarWidth: string;
  sidebarStyle: ThemeUIStyleObject;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name": string;
};
const Sidebar = ({ sidebarComponents, sidebarWidth, sidebarStyle, "data-name": dataName }: SidebarProps) => {
  return (
    <div sx={{ width: sidebarWidth }} data-name={dataName}>
      <div sx={{ display: "flex", flexDirection: "column", width: sidebarWidth, ...sidebarStyle }}>
        {React.Children.map(sidebarComponents, (el, index) => (
          <div sx={{ marginBottom: index < sidebarComponents.length ? "dt_l" : undefined }}>{el}</div>
        ))}
      </div>
    </div>
  );
};
type SidebarLayoutProps = {
  affixTopOffset?: number;
  rightAffixed?: boolean;
  leftAffixed?: boolean;
  leftSidebarComponents?: React.ReactNode[];
  rightSidebarComponents?: React.ReactNode[];
  leftSidebarWidth?: string;
  rightSidebarWidth?: string;
  children?: React.ReactNode;
};

const SidebarLayout = ({
  affixTopOffset = DEFAULT_GUTTER,
  rightAffixed,
  leftAffixed,
  leftSidebarComponents,
  rightSidebarComponents,
  leftSidebarWidth = DEFAULT_WIDTH,
  rightSidebarWidth = DEFAULT_WIDTH,
  children,
}: SidebarLayoutProps): JSX.Element => {
  const [affixed, setAffixed] = useState(false);
  const [sidebarHeight, setSidebarHeight] = useState<string>("auto");

  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    const node = containerRef.current;
    if (node) {
      const currentY = node.getBoundingClientRect().top;
      // we need to convert our props from rem to pixels because the methods on DOM nodes return pixels
      if (currentY <= remToPx(affixTopOffset) && !affixed) {
        setAffixed(true);
      } else if (currentY > remToPx(affixTopOffset) && affixed) {
        setAffixed(false);
      }
    }
  }, [affixTopOffset, affixed, containerRef]);

  useEffect(() => {
    if (leftAffixed || rightAffixed) {
      window.addEventListener("scroll", handleScroll);
      const node = containerRef.current;
      if (node) {
        // offset of container from the top of the page = offset from viewport + scroll position
        const containerPageOffsetY = node.getBoundingClientRect().top + window.pageYOffset;

        const topOffset = Math.max(containerPageOffsetY, remToPx(affixTopOffset));
        const bottomOffset = remToPx(BOTTOM_SPACING);

        // need to convert rem to pixels because window.innerHeight is in px
        const sidebarHeight = window.innerHeight - topOffset - bottomOffset;

        // bulk ignoring existing errors
        // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
        setSidebarHeight(`${sidebarHeight}px`);
      }
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [affixTopOffset, leftAffixed, rightAffixed, handleScroll, containerRef]);

  const getSidebarStyle = (shouldFix: boolean): ThemeUIStyleObject => {
    if (shouldFix) {
      if (affixed) {
        return {
          position: "fixed" as const,
          top: `${affixTopOffset}rem`,
          height: sidebarHeight,
        };
      } else {
        return {
          height: sidebarHeight,
        };
      }
    } else {
      return {};
    }
  };

  const leftSidebar = leftSidebarComponents?.length ? (
    <Sidebar
      sidebarComponents={leftSidebarComponents}
      sidebarWidth={leftSidebarWidth}
      sidebarStyle={getSidebarStyle(!!leftAffixed)}
      data-name="leftSidebarSection"
    />
  ) : null;

  const rightSidebar = rightSidebarComponents?.length ? (
    <Sidebar
      sidebarComponents={rightSidebarComponents}
      sidebarWidth={rightSidebarWidth}
      sidebarStyle={getSidebarStyle(!!rightAffixed)}
      data-name="rightSidebarSection"
    />
  ) : null;

  return (
    <div sx={styles.container} ref={containerRef}>
      <div sx={{ display: "flex", justifyContent: "center", position: "relative" }}>
        {leftSidebar}
        <div
          sx={{
            marginLeft: leftSidebar ? "dt_l" : undefined,
            marginRight: rightSidebar ? "dt_l" : undefined,
          }}
        >
          {children}
        </div>
        {rightSidebar}
      </div>
    </div>
  );
};

const styles: Record<string, ThemeUIStyleObject> = {
  container: {
    // NOTE: instead of bottom margin, use bottom padding to prevent webkit/
    // blink from making the page scrollable when it shouldn't be. Couldn't
    // find a root cause or an issue to point to, but I know changing margin
    // to padding helps
    marginTop: "dt_m",
    marginX: "auto",
    marginBottom: "0",
    paddingBottom: "dt_m",
  },
};

export default SidebarLayout;
