import uniqueId from "lodash/uniqueId";
import { ProfilePicture } from "../../../index";
import CheckboxRadioInput, { CheckboxRadioInputsType, RADIO_CHECKBOX_SIZE } from "./CheckboxRadioInput";
import CheckboxRadioText from "./CheckboxRadioText";
import { InputGroup, InputGroupProps } from "./InputGroup";

export function CheckboxRadioGroupWithAvatar({
  groupName,
  groupType,
  inputs,
  disabled,
  useDisabledStyles,
  limitSingleLine,
  maxWidth,
  ...inputGroupProps
}: {
  groupName: string;
  groupType: "checkbox" | "radio";
  inputs: (CheckboxRadioInputsType & { profileAvatar?: React.ReactNode; id: string })[];
  disabled?: boolean;
  useDisabledStyles?: boolean;
  limitSingleLine?: boolean;
  maxWidth?: string;
} & InputGroupProps) {
  return (
    <InputGroup groupType={groupType} {...inputGroupProps} sx={useDisabledStyles ? { cursor: "not-allowed" } : {}}>
      {inputs.map((inputProps) => {
        const inputId = inputProps.id;
        const inputDetailId = uniqueId("inputGroupItemDetail");
        return (
          <label htmlFor={inputId} key={inputId}>
            <div
              sx={{
                display: "grid",
                alignItems: "center",
                gridTemplateColumns: `45px auto ${RADIO_CHECKBOX_SIZE}px`,
                gap: "s",
                cursor: "pointer",
              }}
            >
              {Object.prototype.hasOwnProperty.call(inputProps, "profileSrc") ? (
                <ProfilePicture src={inputProps.profileSrc} isBlocked={inputProps.isBlocked} />
              ) : (
                (inputProps.profileAvatar ?? null)
              )}
              {inputProps.imgSrc ? (
                <img
                  src={inputProps.imgSrc}
                  width="45px"
                  height="45px"
                  alt=""
                  sx={{ opacity: useDisabledStyles && disabled ? 0.5 : 1 }}
                />
              ) : null}
              <CheckboxRadioText
                labelText={inputProps.labelText}
                detailText={inputProps.detail}
                inputId={inputId}
                detailId={inputDetailId}
                limitSingleLine={limitSingleLine}
                maxWidth={maxWidth}
                disabled={disabled}
                useDisabledStyles={useDisabledStyles}
              />
              <CheckboxRadioInput
                groupType={groupType}
                groupName={groupName}
                inputProps={inputProps}
                inputId={inputId}
                inputDetailId={inputDetailId}
                disabled={disabled}
              />
            </div>
          </label>
        );
      })}
    </InputGroup>
  );
}
