// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type PlayVideoIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let PlayVideoIcon: NessieFunctionComponent<PlayVideoIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "PlayVideoIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: PlayVideoIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.46791 3.25195H20.3519C20.5679 3.25195 20.7479 3.46795 20.7119 3.68395V20.46C20.7119 20.712 20.5679 20.892 20.3519 20.892H3.46791C3.28791 20.892 3.10791 20.712 3.10791 20.46V3.68395C3.10791 3.43195 3.25191 3.25195 3.46791 3.25195ZM19.7759 4.40395H18.4799V5.37595H19.7759V4.40395ZM16.9679 4.40395H15.6719V5.37595H16.9679V4.40395ZM14.0879 4.40395H12.7919V5.37595H14.0879V4.40395ZM11.1359 4.40395H9.83991V5.37595H11.1359V4.40395ZM8.18391 4.40395H6.88791V5.37595H8.18391V4.40395ZM5.41191 4.40395H4.11591V5.37595H5.41191V4.40395ZM4.11591 19.884H5.41191V18.912H4.11591V19.884ZM6.88791 19.884H8.18391V18.912H6.88791V19.884ZM9.83991 19.884H11.1359V18.912H9.83991V19.884ZM12.7919 19.884H14.0879V18.912H12.7919V19.884ZM15.7079 19.884H17.0039V18.912H15.7079V19.884ZM18.4799 19.884H19.7759V18.912H18.4799V19.884ZM16.3199 17.76H19.7759V6.56395H16.3199H12.7199H4.11591V17.76H12.7199H16.3199Z"
          />
          <mask
            id="PlayVideoIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={3}
            y={3}
            width={18}
            height={18}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.46791 3.25195H20.3519C20.5679 3.25195 20.7479 3.46795 20.7119 3.68395V20.46C20.7119 20.712 20.5679 20.892 20.3519 20.892H3.46791C3.28791 20.892 3.10791 20.712 3.10791 20.46V3.68395C3.10791 3.43195 3.25191 3.25195 3.46791 3.25195ZM19.7759 4.40395H18.4799V5.37595H19.7759V4.40395ZM16.9679 4.40395H15.6719V5.37595H16.9679V4.40395ZM14.0879 4.40395H12.7919V5.37595H14.0879V4.40395ZM11.1359 4.40395H9.83991V5.37595H11.1359V4.40395ZM8.18391 4.40395H6.88791V5.37595H8.18391V4.40395ZM5.41191 4.40395H4.11591V5.37595H5.41191V4.40395ZM4.11591 19.884H5.41191V18.912H4.11591V19.884ZM6.88791 19.884H8.18391V18.912H6.88791V19.884ZM9.83991 19.884H11.1359V18.912H9.83991V19.884ZM12.7919 19.884H14.0879V18.912H12.7919V19.884ZM15.7079 19.884H17.0039V18.912H15.7079V19.884ZM18.4799 19.884H19.7759V18.912H18.4799V19.884ZM16.3199 17.76H19.7759V6.56395H16.3199H12.7199H4.11591V17.76H12.7199H16.3199Z"
            />
          </mask>
          <g mask="url(#PlayVideoIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
PlayVideoIcon.nessiePropTypes = iconNessiePropTypes;
PlayVideoIcon = nessiePropCheckerWrapper(PlayVideoIcon);
export default PlayVideoIcon;
