import { useRef, useEffect } from "react";

/**
 *
 * usePrevious hook for React
 *
 * @param {*} currentValue The value whose previous value is to be tracked
 * @returns {*} The previous value
 */
export default function usePrevious<T>(currentValue: T | null | undefined): T | null | undefined {
  const prevRef = useRef<T | null | undefined>(null);

  useEffect(() => {
    prevRef.current = currentValue;
  }, [currentValue]);

  return prevRef.current;
}
