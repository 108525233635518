import * as React from "react";
import { T } from "../..";
import LegalSection from "./LegalSection";

export type LegalSectionWithLanguageProps = {
  entityType?: "teacher" | "parent";
  copy?: React.ComponentPropsWithoutRef<typeof LegalSection>["copy"];
} & Omit<React.ComponentPropsWithoutRef<typeof LegalSection>, "copy">;

// Import this component into projects with access to language files (teach, home, etc);
// If that project needs custom copy for a particular field, they can just pass it in a copy prop
// ie <LegalSectionWithLanguage copy={{creatingAccountExplicit: <div>custom</div>}} />
// Projects without access (external site) can provide all the copy to child component directly
const LegalSectionWithLanguage = ({ entityType = "teacher", ...props }: LegalSectionWithLanguageProps): JSX.Element => {
  const defaultCopy = getDefaultCopy(entityType);
  const copy = props.copy ? { ...defaultCopy, ...props.copy } : defaultCopy;
  return <LegalSection {...props} copy={copy} />;
};

const getDefaultCopy = (entityType: "teacher" | "parent") => ({
  creatingAccountExplicit: <T htmlStr="dojo.common:consent.creating_account_explicit_html" />,
  dataTransfer:
    entityType === "teacher" ? (
      <T
        str="dojo.common:safe_harbor.data_transfer"
        fallback="I agree ClassDojo can transfer information generated by my class's use of ClassDojo to the US, for the purpose of providing us with the service."
      />
    ) : (
      <T
        str="dojo.common:safe_harbor.family_data_transfer"
        fallback="I agree ClassDojo can transfer information generated by my family’s use of ClassDojo to the US, for the purpose of providing us with the service."
      />
    ),
  hide: <T str="dojo.common:actions.hide" fallback="Hide" />,
  expand: <T str="dojo.common:actions.expand" fallback="Expand" />,
  creatingAccountImplicit: <T htmlStr="dojo.common:consent.creating_account_implicit_html" />,
  howItWorks: <T str="dojo.common:consent.how_it_works" fallback="How it works" />,
  howItWorksDetails: (
    <T
      htmlStr="dojo.common:consent.how_it_works_details_html_uk"
      fallback='<div>At ClassDojo, we believe privacy is a fundamental right. We’re always working to protect yours. You can read more about how at our <a href="https://www.classdojo.com/privacycenter/" target="_blank">Privacy Center</a>.</div><div>We ask for some personal information to make ClassDojo work for you. We need your consent to transfer this information to the US, where ClassDojo is based. You can read more about the <a href="https://www.classdojo.com/transparency/" target="_blank">information we collect and why we collect it here</a>.</div><div>ClassDojo complies with local privacy regulations, including GDPR in Europe and the UK.  Please note that the US may not have the same information protection laws as your country, including disclosure to law enforcement authorities.</div>'
    />
  ),
  inControl: <T str="dojo.common:consent.in_control" fallback="You're in control" />,
  inControlDetails: (
    <T
      htmlStr="dojo.common:consent.in_control_details_html"
      fallback='<div>ClassDojo never sells any of your personal data with advertisers or marketers. You can read more about this in our Privacy Policy.</div><div>You can withdraw or change your consent at any time. <a href="https://www.classdojo.com/privacy/#object-restrict-or-withdraw-consent" target="_blank">Visit our privacy support page for help</a>.</div>'
    />
  ),
});

export default LegalSectionWithLanguage;
