import { useState } from "react";
import { translate } from "../../utils/translate";
import { StandardModal } from "../modals";
import RangeCalendar from "./RangeCalendar";
import useWatch from "../../hooks/useWatch";

export type DateRange = { start: Date; end: Date; from: string; to: string };

export type DateRangePickerProps = {
  isOpen: boolean;
  minDate?: Date;
  initialRange?: { from: string; to: string };
  onSelect: (params: DateRange) => void;
  onRequestHide: () => void;
  noRequestHideOnSelect?: boolean;
};

const startOf = (dateStr: string) => new Date(`${dateStr}T00:00:00`);

const DateRangePicker = (props: DateRangePickerProps): JSX.Element => {
  const [from, setFrom] = useState(props.initialRange?.from);
  const [to, setTo] = useState(props.initialRange?.to);

  useWatch(
    [props.initialRange],
    () => {
      setFrom(props.initialRange?.from);
      setTo(props.initialRange?.to);
    },
    { deep: true },
  );

  const { minDate } = props;
  const handleConfirm = () => {
    if (!from || !to) return;

    const [actualFrom, actualTo] = from < to ? [from, to] : [to, from];

    props.onSelect({
      from: actualFrom,
      to: actualTo,
      start: startOf(actualFrom),
      end: startOf(actualTo),
    });
    if (!props.noRequestHideOnSelect) props.onRequestHide();
  };

  const title = translate({
    // eslint-disable-next-line @web-monorepo/no-invalid-translation-prefixes
    str: "dojo.common:date_picker.choose_date_range",
    fallback: "Choose date range",
  });

  return (
    <StandardModal
      data-name="date_range_picker:standard_modal"
      sx={modalStyle}
      title={title}
      onRequestHide={props.onRequestHide}
      isOpen={props.isOpen}
      primaryButton={{
        // eslint-disable-next-line @web-monorepo/no-invalid-translation-prefixes
        text: translate("dojo.common:date_picker.select_dates", "Select dates"),
        props: {
          "data-name": "date-range-picker-confirm",
          onClick: handleConfirm,
          disabled: !(from && to),
        },
      }}
    >
      <RangeCalendar
        value={{
          start: from ? startOf(from) : new Date(),
          end: to ? startOf(to) : new Date(),
        }}
        onChange={(selectedRange) => {
          setFrom(selectedRange.start.toLocaleDateString("en-CA"));
          setTo(selectedRange.end.toLocaleDateString("en-CA"));
        }}
        maxDate={new Date()}
        minDate={minDate}
      />
    </StandardModal>
  );
};

const modalStyle = {
  display: "inline-block",
  width: "auto",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default DateRangePicker;
