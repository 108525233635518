// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type DownloadIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let DownloadIcon: NessieFunctionComponent<DownloadIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "DownloadIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: DownloadIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0888 2C11.4244 2 10.8858 2.53862 10.8858 3.20305V11.0164L9.07225 11.0135C8.43354 11.0125 8.0516 11.7235 8.40493 12.2558L11.4069 16.7778C11.723 17.254 12.4217 17.2551 12.7388 16.78L15.7508 12.2675C16.1053 11.7364 15.7249 11.0241 15.0862 11.0231L13.2919 11.0203V3.20305C13.2919 2.53862 12.7532 2 12.0888 2Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.36364 12.4806C4.11675 12.4806 4.72727 13.0911 4.72727 13.8442V17C4.72727 18.1046 5.6227 19 6.72727 19L17.2725 19C18.377 19 19.2725 18.1046 19.2725 17V13.8442C19.2725 13.0911 19.883 12.4806 20.6361 12.4806C21.3892 12.4806 21.9997 13.0911 21.9997 13.8442V18.9999C21.9997 18.9999 21.9998 19 21.9999 19V19C21.9999 19 22 19 22 19.0001V20.4351C22 21.2276 21.3575 21.8701 20.5649 21.8701L4 21.8701C2.89543 21.8701 2 20.9747 2 19.8701V19.7273V19V13.8442C2 13.0911 2.61052 12.4806 3.36364 12.4806Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
DownloadIcon.nessiePropTypes = iconNessiePropTypes;
DownloadIcon = nessiePropCheckerWrapper(DownloadIcon);
export default DownloadIcon;
