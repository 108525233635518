// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type SchoolwideBadgeIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let SchoolwideBadgeIcon: NessieFunctionComponent<SchoolwideBadgeIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "SchoolwideBadgeIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: SchoolwideBadgeIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 18 18"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5ZM8.63345 4.87585C8.23775 4.26652 7.2935 4.51953 7.25548 5.24507L7.16624 6.94776C7.14924 7.27223 6.92524 7.54886 6.61139 7.63295L4.96446 8.07424C4.26268 8.26229 4.21151 9.2385 4.8898 9.49887L6.48158 10.1099C6.78491 10.2263 6.97878 10.5249 6.96177 10.8493L6.87254 12.552C6.83451 13.2776 7.74714 13.6279 8.20437 13.0633L9.27737 11.7382C9.48185 11.4857 9.82567 11.3936 10.129 11.51L11.7208 12.121C12.3991 12.3814 13.0143 11.6217 12.6186 11.0124L11.6899 9.58243C11.513 9.30993 11.5316 8.95447 11.7361 8.70196L12.8091 7.37691C13.2663 6.81228 12.7339 5.99243 12.0321 6.18047L10.3852 6.62176C10.0713 6.70586 9.73904 6.5783 9.56208 6.3058L8.63345 4.87585Z"
            fill="#83DC1F"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
SchoolwideBadgeIcon.nessiePropTypes = iconNessiePropTypes;
SchoolwideBadgeIcon = nessiePropCheckerWrapper(SchoolwideBadgeIcon);
export default SchoolwideBadgeIcon;
