import * as React from "react";
import { Space, LabelText, BodyText } from "../../nessie";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";

type DetailSectionProps = {
  subheading: React.ReactNode;
  detailText: React.ReactNode;
};

export default function DetailSection({ subheading, detailText }: DetailSectionProps): JSX.Element {
  return (
    <div>
      <LabelText>{subheading}</LabelText>
      <Space size="s" />
      <BodyText level={2} sx={detailSx}>
        {detailText}
      </BodyText>
    </div>
  );
}

const detailSx: ThemeUIStyleObject = {
  a: { color: "dt_content_accent" },
  div: { marginBottom: "s" },
};
