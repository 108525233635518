export default function getZendeskLocale(locale?: string) {
  if (!locale) return "en-us";
  if (startsWithLowerCase(locale, "es")) return "es";
  if (startsWithLowerCase(locale, "zh")) return "zh-cn";
  if (startsWithLowerCase(locale, "pt")) return "pt";
  if (startsWithLowerCase(locale, "tr")) return "tr";
  return "en-us";
}

function startsWithLowerCase(str: string, prefix: string) {
  return str.slice(0, prefix.length).toLowerCase() === prefix;
}
