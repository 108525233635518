// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type ShuffleIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let ShuffleIcon: NessieFunctionComponent<ShuffleIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "ShuffleIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: ShuffleIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.6999 10.4913V9.00003L14.38 9.00003C13.6183 9.00003 13.1428 9.3056 12.7305 9.8522C12.2668 10.4669 11.9359 11.3188 11.5351 12.3594L11.5259 12.3833L11.5259 12.3833C11.1551 13.3462 10.7165 14.485 10.0241 15.3674C9.27454 16.3225 8.21316 17 6.68667 17L3.00009 17C2.4478 17 2 16.5523 2 16C2 15.4477 2.44774 15 3.00002 15L6.68667 15C7.51202 15 8.02313 14.6775 8.45069 14.1327C8.9296 13.5224 9.27124 12.6727 9.66873 11.6406L9.71337 11.5246C10.0728 10.5898 10.4928 9.49769 11.1338 8.64786C11.8529 7.69447 12.8813 7.00003 14.38 7.00003L16.6999 7.00003L16.6999 5.50873C16.6999 5.09511 17.1734 4.86036 17.5026 5.11081L20.7769 7.6021C21.0399 7.8022 21.0399 8.19783 20.7769 8.39793L17.5026 10.8892C17.1734 11.1397 16.6999 10.9049 16.6999 10.4913ZM16.6999 15.0001V13.5088C16.6999 13.0951 17.1734 12.8604 17.5026 13.1108L20.7769 15.6021C21.0399 15.8022 21.0399 16.1979 20.7769 16.398L17.5026 18.8893C17.1734 19.1397 16.6999 18.905 16.6999 18.4913L16.6999 17.0001H14.38C13.1435 17.0001 12.2271 16.5273 11.5405 15.8265C11.9622 15.1907 12.2871 14.5023 12.5464 13.8812C12.6056 13.9745 12.6668 14.0634 12.7305 14.1479C13.1428 14.6945 13.6183 15.0001 14.38 15.0001H16.6999ZM6.68667 7.00006C7.96251 7.00006 8.91343 7.47333 9.62895 8.18643C9.21872 8.82402 8.90034 9.51797 8.64552 10.1395C8.58294 10.0442 8.51818 9.95341 8.45069 9.8674C8.02313 9.32256 7.51202 9.00006 6.68667 9.00006L3.00002 9.00006C2.44774 9.00006 2 8.55235 2 8.00006C2 7.44778 2.4478 7.00006 3.00009 7.00006H6.68667Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ShuffleIcon.nessiePropTypes = iconNessiePropTypes;
ShuffleIcon = nessiePropCheckerWrapper(ShuffleIcon);
export default ShuffleIcon;
