// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type ChevronSmallUpIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let ChevronSmallUpIcon: NessieFunctionComponent<ChevronSmallUpIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "ChevronSmallUpIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: ChevronSmallUpIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M12.0172 9.01428C12.2757 9.0123 12.5348 9.10993 12.732 9.30714L16.7271 13.3023C17.1177 13.6928 17.1177 14.326 16.7271 14.7165C16.3366 15.107 15.7034 15.107 15.3129 14.7165L12.0172 11.4208L8.72143 14.7165C8.33091 15.107 7.69775 15.107 7.30722 14.7165C6.9167 14.326 6.9167 13.6928 7.30722 13.3023L11.3024 9.30714C11.4996 9.10991 11.7587 9.01229 12.0172 9.01428Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ChevronSmallUpIcon.nessiePropTypes = iconNessiePropTypes;
ChevronSmallUpIcon = nessiePropCheckerWrapper(ChevronSmallUpIcon);
export default ChevronSmallUpIcon;
