// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type StickerIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let StickerIcon: NessieFunctionComponent<StickerIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "StickerIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: StickerIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM17 9.5C17 10.33 16.33 11 15.5 11C14.67 11 14 10.33 14 9.5C14 8.67 14.67 8 15.5 8C16.33 8 17 8.67 17 9.5ZM8.5 11C9.33 11 10 10.33 10 9.5C10 8.67 9.33 8 8.5 8C7.67 8 7 8.67 7 9.5C7 10.33 7.67 11 8.5 11ZM16.75 14.75C15.8 16.39 14.03 17.5 12 17.5C9.97 17.5 8.2 16.39 7.25 14.75C7.06 14.42 7.31 14 7.69 14H16.31C16.7 14 16.94 14.42 16.75 14.75Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
StickerIcon.nessiePropTypes = iconNessiePropTypes;
StickerIcon = nessiePropCheckerWrapper(StickerIcon);
export default StickerIcon;
