// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type ChevronSmallRightIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let ChevronSmallRightIcon: NessieFunctionComponent<ChevronSmallRightIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "ChevronSmallRightIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: ChevronSmallRightIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M14.9951 12.012C14.9971 12.2704 14.8995 12.5295 14.7023 12.7267L10.7071 16.7219C10.3166 17.1124 9.68342 17.1124 9.29289 16.7219C8.90237 16.3314 8.90237 15.6982 9.29289 15.3077L12.5886 12.0119L9.29289 8.7162C8.90237 8.32568 8.90237 7.69251 9.29289 7.30199C9.68342 6.91146 10.3166 6.91146 10.7071 7.30199L14.7023 11.2971C14.8995 11.4944 14.9971 11.7535 14.9951 12.012Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ChevronSmallRightIcon.nessiePropTypes = iconNessiePropTypes;
ChevronSmallRightIcon = nessiePropCheckerWrapper(ChevronSmallRightIcon);
export default ChevronSmallRightIcon;
