// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type ReportIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let ReportIcon: NessieFunctionComponent<ReportIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "ReportIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: ReportIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.2411 3.34927C13.2716 1.55024 10.7284 1.55024 9.75889 3.34927L2.31766 17.1566C1.38518 18.8868 2.61738 21 4.55877 21H19.4412C21.3826 21 22.6148 18.8868 21.6823 17.1566L14.2411 3.34927ZM12.0281 7C12.7482 7 13.2927 7.56207 13.2927 8.28222C13.2927 8.51908 13.2129 10.2171 13.134 11.8959C13.0958 12.7071 13.0579 13.5137 13.0293 14.1488C13.0117 14.6933 12.5726 15.1149 11.993 15.1149C11.4485 15.1149 11.0093 14.6933 10.9918 14.1488C10.9537 13.3027 10.8957 12.1523 10.8423 11.0923C10.7726 9.70973 10.7107 8.48106 10.7107 8.28222C10.7107 7.56207 11.2728 7 12.0281 7ZM12.0105 16.1688C12.7834 16.1688 13.4333 16.8187 13.4333 17.5915C13.4333 18.3468 12.7834 18.9967 12.0105 18.9967C11.2377 18.9967 10.6053 18.3468 10.6053 17.5915C10.6053 16.8187 11.2377 16.1688 12.0105 16.1688Z"
            fill="#F04F59"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ReportIcon.nessiePropTypes = iconNessiePropTypes;
ReportIcon = nessiePropCheckerWrapper(ReportIcon);
export default ReportIcon;
