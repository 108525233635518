// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type StudentIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let StudentIcon: NessieFunctionComponent<StudentIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "StudentIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: StudentIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.3879 9.80335C21.1815 9.13569 20.3999 8.78386 19.6423 9.01805L18.4164 9.39702L18.4189 5.11793C18.4203 2.84607 16.5793 1.00361 14.3068 1.00262L10.111 1C7.83899 0.998463 5.9958 2.839 5.99442 5.11048L5.99219 9.31621L5.00322 9.00975C4.24613 8.77456 3.46436 9.12538 3.25717 9.79266L3.05067 10.4578C2.84371 11.1251 3.28947 11.8566 4.04686 12.0916L5.92912 12.6757L5.71255 16.8094C5.68917 16.8919 5.67371 16.9778 5.66694 17.0662L5.34508 21.5475C5.29478 22.2447 5.81953 22.8502 6.51709 22.9005L7.79062 22.992C8.48772 23.0419 9.09383 22.5177 9.14413 21.8202L9.33994 19.096L14.9137 19.0995L15.1062 21.8239C15.1552 22.5209 15.7613 23.046 16.4584 22.9968L17.732 22.9066C18.4294 22.8574 18.9547 22.2527 18.9056 21.5551L18.7039 18.7044C18.6338 17.2274 18.5393 14.5564 18.4778 12.7496C18.5183 12.7405 18.5589 12.7306 18.5995 12.7183L20.5955 12.1013C21.3531 11.8673 21.7997 11.1363 21.5933 10.4684L21.3879 9.80335Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
StudentIcon.nessiePropTypes = iconNessiePropTypes;
StudentIcon = nessiePropCheckerWrapper(StudentIcon);
export default StudentIcon;
