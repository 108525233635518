// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type CheckboxSelectedIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let CheckboxSelectedIcon: NessieFunctionComponent<CheckboxSelectedIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "CheckboxSelectedIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: CheckboxSelectedIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 3.00012C5.68629 3.00012 3 5.68641 3 9.00012V15.0001C3 18.3138 5.68629 21.0001 9 21.0001H15C18.3137 21.0001 21 18.3138 21 15.0001V9.00012C21 5.68641 18.3137 3.00012 15 3.00012H9ZM11.2071 15.3317C11.0018 15.537 10.7295 15.6344 10.4605 15.6238C10.1917 15.6343 9.91947 15.5369 9.71424 15.3317L7.5929 13.2104C7.20237 12.8198 7.20237 12.1867 7.5929 11.7961C7.98342 11.4056 8.61659 11.4056 9.00712 11.7961L10.4607 13.2497L15.0174 8.69302C15.4079 8.30249 16.0411 8.30249 16.4316 8.69302C16.8221 9.08355 16.8221 9.71672 16.4316 10.1072L11.2071 15.3317Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
CheckboxSelectedIcon.nessiePropTypes = iconNessiePropTypes;
CheckboxSelectedIcon = nessiePropCheckerWrapper(CheckboxSelectedIcon);
export default CheckboxSelectedIcon;
