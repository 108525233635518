// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type ChartIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let ChartIcon: NessieFunctionComponent<ChartIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "ChartIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: ChartIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2055 2C17.8906 2.32061 20.0147 4.04389 20.9765 6.32824L14.3238 9.17366L15.2055 2ZM12.4402 4.52481L11.3983 13.1412C11.3983 13.1412 19.2131 9.69466 19.2532 9.77481C19.7341 11.0172 19.9345 12.4599 19.7742 13.9027C19.2131 18.5115 15.0051 21.7977 10.3964 21.2366C5.78757 20.6756 2.50131 16.4676 3.06238 11.8588C3.62345 7.25 7.83147 3.96374 12.4402 4.52481Z"
          />
          <mask
            id="ChartIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={3}
            y={2}
            width={18}
            height={20}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.2055 2C17.8906 2.32061 20.0147 4.04389 20.9765 6.32824L14.3238 9.17366L15.2055 2ZM12.4402 4.52481L11.3983 13.1412C11.3983 13.1412 19.2131 9.69466 19.2532 9.77481C19.7341 11.0172 19.9345 12.4599 19.7742 13.9027C19.2131 18.5115 15.0051 21.7977 10.3964 21.2366C5.78757 20.6756 2.50131 16.4676 3.06238 11.8588C3.62345 7.25 7.83147 3.96374 12.4402 4.52481Z"
            />
          </mask>
          <g mask="url(#ChartIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ChartIcon.nessiePropTypes = iconNessiePropTypes;
ChartIcon = nessiePropCheckerWrapper(ChartIcon);
export default ChartIcon;
