declare global {
  interface Window {
    disableAnimations?: boolean;
  }
}

// This method checks whether the disableAnimations global is
// enabled since it can be disabled by calling disableAnimationsForCypressTests
export const isAnimationEnabled = () => {
  if (typeof window !== "undefined") {
    if (window.disableAnimations) {
      // eslint-disable-next-line no-console
      console.log("Animations disabled! Check global declaration if this was not intended.");
    }
    return !window.disableAnimations;
  }
  return false;
};
