// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type IdeasIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let IdeasIcon: NessieFunctionComponent<IdeasIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "IdeasIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: IdeasIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.20571 21.4061C5.90398 21.0956 7.65905 20.5864 8.4666 18.4666C8.4666 18.4383 8.43912 18.3811 8.38269 18.3811C7.65905 18.805 6.7119 18.8612 6.40596 18.8612C6.32167 18.8612 6.2942 18.8329 6.2942 18.7477C6.26672 18.4372 6.18244 17.4759 6.51734 16.7131C6.54519 16.6852 6.48987 16.6279 6.43343 16.6279C4.4567 17.7023 4.15039 19.5101 4.039 21.2637C4.06648 21.3503 4.1489 21.4061 4.20571 21.4061ZM2.03294 12.9268C1.94903 13.0402 2.03294 13.2094 2.19965 13.1243C4.39989 12.3898 6.57118 12.7282 7.32378 12.8974C7.49048 12.9257 7.54729 13.0949 7.51833 13.2083L7.01784 15.1322C6.99036 15.2456 7.04531 15.4148 7.18454 15.4427C7.49048 15.5561 8.04741 15.8109 8.57687 16.2627C9.10632 16.7723 9.43974 17.2799 9.5515 17.5904C9.63578 17.7038 9.74605 17.76 9.85744 17.7038L11.6675 16.9694C11.8345 16.9415 11.9459 16.9694 12.0302 17.1386C12.2537 17.8452 12.8931 20.0505 12.393 22.3396C12.3651 22.453 12.5043 22.5664 12.6161 22.453C13.675 21.2369 15.7635 18.5239 15.5953 14.539C15.5953 14.4535 15.6228 14.4256 15.6792 14.3698C21.1093 9.56485 21.9172 3.99715 22 1C19.1036 1.42397 13.701 2.89296 9.63578 8.91402C9.5515 8.9419 9.52402 8.99956 9.43974 8.99956C5.54123 9.31009 3.11933 11.7405 2.03294 12.9268ZM16.2621 7.5008C17.0701 8.2353 17.1529 9.53508 16.4292 10.383C15.7052 11.2031 14.4246 11.2871 13.5892 10.5526C12.7813 9.81772 12.6989 8.51794 13.4225 7.67001C14.1736 6.82208 15.4561 6.73842 16.2621 7.5008Z"
          />
          <mask
            id="IdeasIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={2}
            y={1}
            width={20}
            height={22}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.20571 21.4061C5.90398 21.0956 7.65905 20.5864 8.4666 18.4666C8.4666 18.4383 8.43912 18.3811 8.38269 18.3811C7.65905 18.805 6.7119 18.8612 6.40596 18.8612C6.32167 18.8612 6.2942 18.8329 6.2942 18.7477C6.26672 18.4372 6.18244 17.4759 6.51734 16.7131C6.54519 16.6852 6.48987 16.6279 6.43343 16.6279C4.4567 17.7023 4.15039 19.5101 4.039 21.2637C4.06648 21.3503 4.1489 21.4061 4.20571 21.4061ZM2.03294 12.9268C1.94903 13.0402 2.03294 13.2094 2.19965 13.1243C4.39989 12.3898 6.57118 12.7282 7.32378 12.8974C7.49048 12.9257 7.54729 13.0949 7.51833 13.2083L7.01784 15.1322C6.99036 15.2456 7.04531 15.4148 7.18454 15.4427C7.49048 15.5561 8.04741 15.8109 8.57687 16.2627C9.10632 16.7723 9.43974 17.2799 9.5515 17.5904C9.63578 17.7038 9.74605 17.76 9.85744 17.7038L11.6675 16.9694C11.8345 16.9415 11.9459 16.9694 12.0302 17.1386C12.2537 17.8452 12.8931 20.0505 12.393 22.3396C12.3651 22.453 12.5043 22.5664 12.6161 22.453C13.675 21.2369 15.7635 18.5239 15.5953 14.539C15.5953 14.4535 15.6228 14.4256 15.6792 14.3698C21.1093 9.56485 21.9172 3.99715 22 1C19.1036 1.42397 13.701 2.89296 9.63578 8.91402C9.5515 8.9419 9.52402 8.99956 9.43974 8.99956C5.54123 9.31009 3.11933 11.7405 2.03294 12.9268ZM16.2621 7.5008C17.0701 8.2353 17.1529 9.53508 16.4292 10.383C15.7052 11.2031 14.4246 11.2871 13.5892 10.5526C12.7813 9.81772 12.6989 8.51794 13.4225 7.67001C14.1736 6.82208 15.4561 6.73842 16.2621 7.5008Z"
            />
          </mask>
          <g mask="url(#IdeasIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
IdeasIcon.nessiePropTypes = iconNessiePropTypes;
IdeasIcon = nessiePropCheckerWrapper(IdeasIcon);
export default IdeasIcon;
