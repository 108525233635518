import { useLayoutEffect, useRef, useState } from "react";
import { Button, Space, HeadlineText, LabelText, BodyText } from "../../nessie";
import { CloseIcon, LogoutIcon, ChevronSmallDownIcon, ChevronSmallUpIcon } from "../../nessie/icons";
import { translate } from "../../utils/translate";
import DataTransferLegalSection from "./DataTransferLegalSection";
import LearnMoreDetail from "./LearnMoreDetail";
import { Image } from "../../components/misc/Image";

export type DataTransferConsentBlockerProps = {
  logout: () => void;
  dismiss: () => void;
  canDismiss?: boolean;
  startDataConsent: (args: { dataTransferConsent: boolean }) => void;
  entityType: "teacher" | "parent";
  isInUK: boolean;
};

const DataTransferConsentBlocker = ({
  logout,
  dismiss,
  canDismiss,
  startDataConsent,
  entityType,
  isInUK,
}: DataTransferConsentBlockerProps): JSX.Element => {
  const [dataConsent, setDataConsent] = useState(false);
  const [showExpandedInfo, setShowExpandedInfo] = useState(false);
  const [expandedSectionHeight, setExpandedSectionHeight] = useState(0);
  const expandedContents = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    showExpandedInfo && expandedContents.current
      ? setExpandedSectionHeight(expandedContents.current.scrollHeight)
      : setExpandedSectionHeight(0);
  }, [showExpandedInfo]);

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "dt_background_primary",
        minHeight: "100vh",
      }}
      data-name="dataTransferConsentBlocker"
    >
      <div sx={{ display: "flex", justifyContent: "center" }}>
        <div sx={{ padding: "m" }}>
          <Image alt="ClassDojo Logo" src="shared/classdojo-logo.png" height={43} width={148} />
        </div>
        {canDismiss ? (
          <Button
            sx={{ position: "absolute", right: "0" }}
            kind="tertiary"
            icon={<CloseIcon size="m" />}
            onClick={dismiss}
            data-name="dismissDataTransferConsent"
          />
        ) : (
          <Button
            sx={{ position: "absolute", right: "0" }}
            kind="tertiary"
            icon={<LogoutIcon size="m" />}
            onClick={logout}
            data-name="logoutDataTransferConsent"
          >
            {translate("dojo.common:actions.logout")}
          </Button>
        )}
      </div>
      <div sx={{ maxWidth: "50rem", textAlign: "center", marginBottom: "dt_m" }}>
        <Image alt="lock with checkmark" src="data_transfer_consent/lock-with-checkmark.png" height={105} width={120} />
        <HeadlineText as="h2" sx={{ marginBottom: "dt_xs" }}>
          {isInUK
            ? translate({ str: "dojo.common:consent.gdpr_compliant_uk", fallback: "ClassDojo is UK GDPR Compliant 🎉" })
            : translate({ str: "dojo.common:consent.gdpr_compliant", fallback: "ClassDojo is GDPR Compliant 🎉" })}
        </HeadlineText>
        <LabelText kind="tertiary" sx={{ marginBottom: "dt_m" }}>
          {translate({
            str: "dojo.common:consent.privacy_rights",
            fallback: "At ClassDojo, we believe privacy is a fundamental right.",
          })}
        </LabelText>
        <BodyText sx={{ marginBottom: "dt_m" }}>
          {translate({
            str: "dojo.common:consent.data_transfer_consent_notice_v2",
            fallback:
              "Due to new privacy regulations for our international users, we need your updated permission to continue to transfer information to the US to use ClassDojo.",
          })}
        </BodyText>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            return startDataConsent({ dataTransferConsent: dataConsent });
          }}
        >
          <DataTransferLegalSection setDataTransferConsentGranted={setDataConsent} entityType={entityType} />
          <div
            ref={expandedContents}
            sx={{
              paddingLeft: "dt_l",
              paddingTop: "dt_l",
              textAlign: "left",
              overflow: "hidden",
              maxHeight: expandedSectionHeight,
              transition: "max-height .4s ease",
              WebkitTransition: "max-height .4s ease",
            }}
          >
            <LearnMoreDetail isInUK={isInUK} />
          </div>
          <div sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              kind="tertiary"
              onClick={() => setShowExpandedInfo(!showExpandedInfo)}
              icon={showExpandedInfo ? <ChevronSmallUpIcon /> : <ChevronSmallDownIcon />}
              data-name="data-transfer-learn-more"
            >
              {showExpandedInfo
                ? translate({
                    str: "dojo.common:actions.hide",
                    fallback: "Hide",
                  })
                : translate({
                    str: "dojo.common:actions.learn_more",
                    fallback: "Learn more",
                  })}
            </Button>
            <Space size="m" kind="inline" />
            <Button type="submit" kind="primary" disabled={!dataConsent} data-name="submitDataTransferConsent">
              {translate({
                str: "dojo.common:actions.continue",
                fallback: "Continue",
              })}
            </Button>
          </div>
          <div>
            <BodyText level={2} sx={{ fontStyle: "italic", marginTop: "dt_xl" }}>
              {translate({
                str: "dojo.common:consent.footnote",
                fallback: "You will need to provide updated consent in order to continue using ClassDojo.",
              })}
            </BodyText>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DataTransferConsentBlocker;
