import { BodyText, Button, Modal, ModalConfirmDialog, ModalTitle, Space } from "../../nessie";

type ConfirmModalProps = {
  title: string;
  bodyText?: React.ReactNode;
  confirmText: string;
  cancelText: string;
  danger?: boolean;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
  confirm: () => void;
  close: () => void;
};

export const ConfirmModal = ({
  title,
  bodyText,
  confirmText,
  cancelText,
  danger,
  confirm,
  close,
  ["data-name"]: dataName,
}: ConfirmModalProps) => {
  return (
    <Modal data-name={dataName} isOpen={true} label={title}>
      <ModalConfirmDialog>
        <ModalTitle>{title}</ModalTitle>
        <Space size="l" />
        <BodyText>{bodyText}</BodyText>
        <Space size="l" />
        <div
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "dt_m",
          }}
        >
          <Button kind="secondary" onClick={close} data-name={`${dataName}:cancel`} sx={{ flex: "1 1 0px" }}>
            {cancelText}
          </Button>
          <Button
            data-name={`${dataName}:primary_button`}
            kind={danger ? "destructive" : "primary"}
            onClick={() => {
              confirm();
              close();
            }}
            sx={{ flex: "1 1 0px" }}
          >
            {confirmText}
          </Button>
        </div>
      </ModalConfirmDialog>
    </Modal>
  );
};
