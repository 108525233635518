let stubHref: string | null = null;

function getHref(): string {
  return stubHref || window.location.href;
}

export function setHref(href: string | null): void {
  stubHref = href;
}

export default function formatUrl(url: string): { newUrl: string; target: string } {
  const href = getHref();

  let target = "_blank";
  let newUrl = url;
  let addToDirUrlTokens;

  if (newUrl[0] === "/") {
    newUrl = `/#${newUrl}`;
    target = "";
  } else if (newUrl[0] === "?") {
    if (href.indexOf("?") > -1) {
      newUrl = `&${newUrl.slice(1)}`; // replace ? with & if newUrl already has a query string
    }
    newUrl = href + newUrl;
    target = "";
  } else if (
    (addToDirUrlTokens = url.match(
      /https:\/\/www\.classdojo\.com\/ul\/t\/class\?classID=([^ ]+)&target=addStudentsToDirectory$/,
    ))
  ) {
    // replace addStudentsToDirectory universal link with direct link that opens in existing tab
    newUrl = `https://teach.classdojo.com/#/classes/${addToDirUrlTokens[1]}/points?modal=addStudentsToDirectory`;
    target = "";
  }
  return { newUrl, target };
}
