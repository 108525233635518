import { DropdownMenu, MenuOptions } from "../../nessie";
import { HelpIcon } from "../../nessie/icons";
import logEvent from "../../utils/logEvent";
import { translate } from "../../utils/translate";
import getZendeskLocale from "../../utils/zendeskLocale";

export type HelpCenterProps = {
  type: "teacher" | "parent";
  locale: string;
  hideTicketLink?: boolean;
  purple?: boolean;
};

const HelpCenter = ({ type, locale, hideTicketLink, purple }: HelpCenterProps): JSX.Element => {
  const forTeacher = type === "teacher";

  const _openHelpdesk = () => {
    logEvent("help_desk_menu.click_helpdesk");
    const zendeskLocale = getZendeskLocale(locale);
    const article = type === "parent" ? 200185365 : 200185275;
    const link = `https://classdojo.zendesk.com/hc/${zendeskLocale}/categories/${article}`;
    window.open(link, "_blank");
  };

  const _openTicketLink = () => {
    logEvent("help_desk_menu.click_submit_ticket");
    const zendeskLocale = getZendeskLocale(locale);
    const article = type === "parent" ? 28909 : 27695;
    const link = `https://classdojo.zendesk.com/hc/${zendeskLocale}/requests/new?ticket_form_id=${article}`;
    window.open(link, "_blank");
  };

  const options: MenuOptions[] = [];

  forTeacher
    ? options.push({
        label: translate("dojo.common:support.teacher_helpdesk", "Teacher Helpdesk"),
        onClick: _openHelpdesk,
        opensAModal: false,
        "data-name": "teacher_helpdesk",
      })
    : options.push({
        label: translate("dojo.common:support.parent_helpdesk", "Parent Helpdesk"),
        onClick: _openHelpdesk,
        opensAModal: false,
        "data-name": "parent_helpdesk",
      });

  // Can remove this once helpdesk is rolled out to everyone.
  if (!hideTicketLink) {
    options.push({
      onClick: _openTicketLink,
      label: translate({ str: "dojo.common:support.submit_ticket", fallback: "Email support" }),
      opensAModal: false,
    });
  }

  return (
    <DropdownMenu
      data-name="help_center:dropdown_menu"
      label={
        // eslint-disable-next-line @web-monorepo/no-invalid-translation-prefixes
        translate({ fallback: "Open help center menu", str: "dojo.common:header.open_help" })
      }
      trigger={<MenuTrigger purple={purple} />}
      options={options}
      align="end"
    />
  );
};

export default HelpCenter;

function MenuTrigger({ purple }: { purple?: boolean }) {
  return (
    <HelpIcon color={purple ? "dt_content_tertiary" : "dt_content_light"} sx={{ width: "28px", height: "28px" }} />
  );
}
