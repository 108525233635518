import { isValidElement, cloneElement } from "react";
import { ThemeUIStyleObject } from "../../stylingLib";
import { NessieThemeColors } from "../theme";
import { NumberBadgeForWeb, NumberBadgeProps, NumberBadgeSizes } from "./NumberBadge";

export type IconBadgeProps = Omit<NumberBadgeProps, "value"> & {
  icon: React.ReactNode;
  value?: number;
};

export type IconBadgeSizes = NumberBadgeSizes;

export const IconBadge = ({ size = "m", icon, value, ...rest }: IconBadgeProps): JSX.Element => {
  // if there is a number value, the padding on the sides needs to me bigger
  const paddingX = value !== undefined ? paddingXStyles[size] : {};
  return (
    <NumberBadgeForWeb.NumberBadge size={size} sx={{ ...gapSizeStyles[size], ...paddingX }} value={value} {...rest}>
      {icon && isValidElement<{ size: NumberBadgeSizes; color: NessieThemeColors }>(icon) ? (
        <>{cloneElement(icon, { size, color: "dt_content_light" })}</>
      ) : null}
    </NumberBadgeForWeb.NumberBadge>
  );
};

const gapSizeStyles: Record<NumberBadgeSizes, ThemeUIStyleObject> = {
  xs: {
    gap: "dt_xxs",
  },
  s: {
    gap: "dt_xs",
  },
  m: {
    gap: "dt_xs",
  },
  l: {
    gap: "dt_s",
  },
  xl: {
    gap: "dt_m",
  },
};

const paddingXStyles: Record<NumberBadgeSizes, ThemeUIStyleObject> = {
  xs: {
    paddingX: "dt_xs",
  },
  s: {
    paddingX: "dt_s",
  },
  m: {
    paddingX: "dt_s",
  },
  l: {
    paddingX: "dt_s",
  },
  xl: {
    paddingX: "dt_s",
  },
};
