// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type CheckboxEmptyIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let CheckboxEmptyIcon: NessieFunctionComponent<CheckboxEmptyIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "CheckboxEmptyIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: CheckboxEmptyIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 5.00012H9C6.79086 5.00012 5 6.79098 5 9.00012V15.0001C5 17.2093 6.79086 19.0001 9 19.0001H15C17.2091 19.0001 19 17.2093 19 15.0001V9.00012C19 6.79098 17.2091 5.00012 15 5.00012ZM9 3.00012C5.68629 3.00012 3 5.68641 3 9.00012V15.0001C3 18.3138 5.68629 21.0001 9 21.0001H15C18.3137 21.0001 21 18.3138 21 15.0001V9.00012C21 5.68641 18.3137 3.00012 15 3.00012H9Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
CheckboxEmptyIcon.nessiePropTypes = iconNessiePropTypes;
CheckboxEmptyIcon = nessiePropCheckerWrapper(CheckboxEmptyIcon);
export default CheckboxEmptyIcon;
