import { createCalendar, getLocalTimeZone } from "@internationalized/date";
import { useRangeCalendar } from "@react-aria/calendar";
import { useRangeCalendarState } from "@react-stately/calendar";
import { useRef } from "react";
import CalendarContent, { getCalendarDate } from "./calendar/CalendarContent";

type DatePickerProps = {
  value: { start: Date; end: Date };
  minDate?: Date;
  maxDate?: Date;
  className?: string;
  onChange?: (date: { start: Date; end: Date }) => void;
};

/**
 * Date picker. Wrapper around @react-aria/calendar with our custom flavor
 * for Class Dojo.
 */
const RangeCalendar = ({ value, onChange, minDate, maxDate, className, ...props }: DatePickerProps): JSX.Element => {
  const localeInfo = new Intl.DateTimeFormat().resolvedOptions();

  const state = useRangeCalendarState({
    ...props,
    minValue: minDate ? getCalendarDate(minDate) : undefined,
    maxValue: maxDate ? getCalendarDate(maxDate) : undefined,
    value: getRangeCalendarDate(value),
    onChange: (selectedRange) => {
      onChange?.({
        start: selectedRange.start.toDate(getLocalTimeZone()),
        end: selectedRange.end.toDate(getLocalTimeZone()),
      });
    },
    locale: localeInfo.locale,
    createCalendar,
  });

  const ref = useRef(null);
  const calendarProps = useRangeCalendar(props, state, ref);

  function getRangeCalendarDate(dateRange: { start: Date; end: Date }) {
    const { start, end } = dateRange;
    return { start: getCalendarDate(start), end: getCalendarDate(end) };
  }
  return <CalendarContent state={state} {...props} {...calendarProps} className={className} containerRef={ref} />;
};

export default RangeCalendar;
