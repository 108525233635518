// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type ChevronSmallLeftIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let ChevronSmallLeftIcon: NessieFunctionComponent<ChevronSmallLeftIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "ChevronSmallLeftIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: ChevronSmallLeftIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M9.00488 12.012C9.00291 12.2705 9.10053 12.5296 9.29774 12.7268L13.2929 16.722C13.6834 17.1125 14.3166 17.1125 14.7071 16.722C15.0976 16.3314 15.0976 15.6983 14.7071 15.3077L11.4114 12.012L14.7071 8.71626C15.0976 8.32574 15.0976 7.69257 14.7071 7.30205C14.3166 6.91152 13.6834 6.91152 13.2929 7.30205L9.29774 11.2972C9.10052 11.4944 9.0029 11.7535 9.00488 12.012Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ChevronSmallLeftIcon.nessiePropTypes = iconNessiePropTypes;
ChevronSmallLeftIcon = nessiePropCheckerWrapper(ChevronSmallLeftIcon);
export default ChevronSmallLeftIcon;
