// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type CheckEmptyIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let CheckEmptyIcon: NessieFunctionComponent<CheckEmptyIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "CheckEmptyIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: CheckEmptyIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.6612 6.33884C14.5479 3.22557 9.49983 3.214 6.33523 6.34244C3.22556 9.456 3.21521 14.5014 6.34244 17.6648C9.45481 20.7732 14.4975 20.7848 17.6611 17.6611C20.786 14.4963 20.7732 9.45091 17.6612 6.33884ZM19.0754 19.0754C15.1357 22.9749 8.82412 22.9749 4.92462 19.0754C1.02513 15.1357 1.02513 8.82412 4.92462 4.92462C8.86432 1.02513 15.1759 1.02513 19.0754 4.92462C22.9749 8.82412 22.9749 15.1357 19.0754 19.0754Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
CheckEmptyIcon.nessiePropTypes = iconNessiePropTypes;
CheckEmptyIcon = nessiePropCheckerWrapper(CheckEmptyIcon);
export default CheckEmptyIcon;
