// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type PlusBrandIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let PlusBrandIcon: NessieFunctionComponent<PlusBrandIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "PlusBrandIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: PlusBrandIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M19.0244 10.0244C16.2366 10.0244 13.9756 7.76341 13.9756 4.97561C13.9756 3.87805 13.0976 3 12 3C10.9024 3 10.0244 3.87805 10.0244 4.97561C10.0244 7.76341 7.76341 10.0244 4.97561 10.0244C3.87805 10.0244 3 10.9024 3 12C3 13.0976 3.87805 13.9756 4.97561 13.9756C7.76341 13.9756 10.0244 16.2366 10.0244 19.0244C10.0244 20.122 10.9024 21 12 21C13.0976 21 13.9756 20.122 13.9756 19.0244C13.9756 16.2366 16.2366 13.9756 19.0244 13.9756C20.122 13.9756 21 13.0976 21 12C21 10.9024 20.122 10.0244 19.0244 10.0244Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
PlusBrandIcon.nessiePropTypes = iconNessiePropTypes;
PlusBrandIcon = nessiePropCheckerWrapper(PlusBrandIcon);
export default PlusBrandIcon;
