import type { ComponentProps } from "react";
import * as SwitchPrimitive from "@radix-ui/react-switch";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";

type SwitchProps = {
  checked: boolean;
  onCheckedChange: (value: boolean) => void;
  "aria-label"?: string;
  id?: string;
  disabled?: boolean;
  className?: string;
  value?: ComponentProps<(typeof SwitchPrimitive)["Root"]>["value"];
};

export const Switch = ({ checked, ...rest }: SwitchProps) => {
  return (
    <SwitchPrimitive.Root defaultChecked checked={checked} sx={switchRootStyle} {...rest}>
      <SwitchPrimitive.Thumb sx={switchThumbStyle} />
    </SwitchPrimitive.Root>
  );
};

const switchRootStyle: ThemeUIStyleObject = {
  all: "unset",
  width: "54px",
  height: "30px",
  backgroundColor: "dt_taro30",
  borderRadius: "dt_radius_l",
  position: "relative",
  ":focus-visible": {
    outline: "2px solid rgb(0, 95, 204)",
  },
  cursor: "pointer",
  '&[data-state="checked"]': { backgroundColor: "dt_content_accent" },
  ":hover": {
    backgroundColor: "dt_taro40",
  },
  '&[data-state="checked"]:hover': { backgroundColor: "dt_content_accent", opacity: 0.9 },
  ":disabled": {
    cursor: "not-allowed",
    opacity: 0.3,
  },
};

const switchThumbStyle: ThemeUIStyleObject = {
  display: "block",
  width: "24px",
  height: "24px",
  backgroundColor: "dt_background_primary",
  borderRadius: "dt_radius_l",
  transition: "transform 100ms",
  transform: "translateX(3px)",
  willChange: "transform",
  '&[data-state="checked"]': { transform: "translateX(27px)" },
};
