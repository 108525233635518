import * as React from "react";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { StandardModal } from "./index";

type FloaterProps = Omit<React.ComponentPropsWithoutRef<typeof StandardModal>, "children" | "showModalOverflow"> & {
  floater: React.ReactNode;
  floaterStyle?: ThemeUIStyleObject;
  children: React.ReactNode;
};

const Floater = ({ floater, floaterStyle, children, ...props }: FloaterProps): JSX.Element => {
  return (
    <StandardModal {...props} showModalOverflow={true}>
      {children}
      <div sx={{ ...defaultFloaterStyle, ...floaterStyle }}>{floater}</div>
    </StandardModal>
  );
};

export default Floater;

const defaultFloaterStyle: ThemeUIStyleObject = {
  width: "100%",
  position: "absolute",
  background: "transparent",
  color: "dt_content_light",
  fontSize: "1.6rem",
  zIndex: 10000000000,
  top: "-40px",
};
