import { UnstyledButton } from "../..";
import BodyText from "./typography/bodyText";

type TileGroupSelectOption = {
  title: string;
  value: string;
  imgSrc?: string;
};

type TileGroupSelectProps = {
  options: TileGroupSelectOption[];
  selectedValues: string[];
  setSelectedValues: (values: string[]) => void;
  imgSize?: number;
  itemsPerRow?: number;
  maxWidth?: number;
  cardHeight?: number;
};

export const TileGroupSelect = ({
  options,
  selectedValues,
  setSelectedValues,
  imgSize = 42,
  itemsPerRow = 3,
  maxWidth = 800,
  cardHeight = 138,
}: TileGroupSelectProps) => {
  const toggleValueSelection = (value: string) => {
    if (selectedValues.includes(value)) {
      const valuesWithRemovedSelection = selectedValues.filter((selectedValue) => selectedValue !== value);
      return setSelectedValues(valuesWithRemovedSelection);
    }

    setSelectedValues(selectedValues.concat(value));
  };

  return (
    <div sx={{ display: "flex", flexWrap: "wrap", maxWidth, justifyContent: "center", position: "relative" }}>
      {options.map((option) => {
        const isSelected = selectedValues.includes(option.value);

        return (
          <UnstyledButton
            data-name="tile_group_select:options:body_text"
            role="checkbox"
            aria-checked={selectedValues.includes(option.value)}
            aria-label={option.title}
            tabIndex={0}
            key={option.value}
            onClick={() => toggleValueSelection(option.value)}
            sx={{
              padding: "dt_m",
              border: isSelected ? "dt_active" : "dt_card",
              borderRadius: "dt_radius_m",
              width: `${Math.round(100 / (itemsPerRow + 1))}%`,
              minWidth: maxWidth / (itemsPerRow + 1),
              height: cardHeight,
              alignItems: "center",
              margin: "dt_s",
              cursor: "pointer",
              backgroundColor: "dt_background_primary",
              justifyContent: option.imgSrc ? "none" : "center",
              boxShadow: isSelected ? "none" : `dt_shadow_shadezies`,
              display: "flex",
              flexDirection: "column",
              position: "relative",
              "&:hover": {
                boxShadow: "none",
              },
              transition: "box-shadow ease-in-out 0.2s",
            }}
          >
            {option.imgSrc ? (
              <div sx={{ paddingBottom: "dt_s", position: "relative" }}>
                <img height={imgSize || 42} width={imgSize || 42} src={option.imgSrc} alt={option.value} />
              </div>
            ) : null}
            <BodyText textAlign="center">{option.title}</BodyText>
          </UnstyledButton>
        );
      })}
    </div>
  );
};
