// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type VideoIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let VideoIcon: NessieFunctionComponent<VideoIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "VideoIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: VideoIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.7671 4H2.40506C2.16203 4 2 4.20253 2 4.48608V19.5139C2 19.7975 2.20253 20 2.40506 20H21.7266C21.9696 20 22.1316 19.7975 22.1316 19.5139V4.48608C22.1722 4.20253 21.9696 4 21.7671 4ZM19.5797 5.2962H21.0785V6.43038H19.5797V5.2962ZM16.3797 5.2962H17.8785V6.43038H16.3797V5.2962ZM13.0987 5.2962H14.5975V6.43038H13.0987V5.2962ZM9.6962 5.2962H11.1949V6.43038H9.6962V5.2962ZM6.33418 5.2962H7.79241V6.43038H6.29367V5.2962H6.33418ZM3.13418 5.2962H4.63291V6.43038H3.13418V5.2962ZM4.63291 18.8253H3.13418V17.6911H4.63291V18.8253ZM7.79241 18.8253H6.29367V17.6911H7.79241V18.8253ZM11.1949 18.8253H9.6962V17.6911H11.1949V18.8253ZM11.2354 15.7063H3.13418V8.4557H11.2354V15.7063ZM14.557 18.8253H13.0582V17.6911H14.557V18.8253ZM17.8785 18.8253H16.3797V17.6911H17.8785V18.8253ZM21.038 18.8253H19.5392V17.6911H21.038V18.8253ZM21.0785 15.7063H12.9772V8.4557H21.0785V15.7063Z"
          />
          <mask
            id="VideoIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={2}
            y={4}
            width={21}
            height={16}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.7671 4H2.40506C2.16203 4 2 4.20253 2 4.48608V19.5139C2 19.7975 2.20253 20 2.40506 20H21.7266C21.9696 20 22.1316 19.7975 22.1316 19.5139V4.48608C22.1722 4.20253 21.9696 4 21.7671 4ZM19.5797 5.2962H21.0785V6.43038H19.5797V5.2962ZM16.3797 5.2962H17.8785V6.43038H16.3797V5.2962ZM13.0987 5.2962H14.5975V6.43038H13.0987V5.2962ZM9.6962 5.2962H11.1949V6.43038H9.6962V5.2962ZM6.33418 5.2962H7.79241V6.43038H6.29367V5.2962H6.33418ZM3.13418 5.2962H4.63291V6.43038H3.13418V5.2962ZM4.63291 18.8253H3.13418V17.6911H4.63291V18.8253ZM7.79241 18.8253H6.29367V17.6911H7.79241V18.8253ZM11.1949 18.8253H9.6962V17.6911H11.1949V18.8253ZM11.2354 15.7063H3.13418V8.4557H11.2354V15.7063ZM14.557 18.8253H13.0582V17.6911H14.557V18.8253ZM17.8785 18.8253H16.3797V17.6911H17.8785V18.8253ZM21.038 18.8253H19.5392V17.6911H21.038V18.8253ZM21.0785 15.7063H12.9772V8.4557H21.0785V15.7063Z"
            />
          </mask>
          <g mask="url(#VideoIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
VideoIcon.nessiePropTypes = iconNessiePropTypes;
VideoIcon = nessiePropCheckerWrapper(VideoIcon);
export default VideoIcon;
