// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type NavigationMenuIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let NavigationMenuIcon: NessieFunctionComponent<NavigationMenuIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "NavigationMenuIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: NavigationMenuIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 5C2 3.89543 2.89543 3 4 3H20C21.1046 3 22 3.89543 22 5C22 6.10457 21.1046 7 20 7H4C2.89543 7 2 6.10457 2 5ZM2 19C2 17.8954 2.89543 17 4 17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19ZM4 10C2.89543 10 2 10.8954 2 12C2 13.1046 2.89543 14 4 14H20C21.1046 14 22 13.1046 22 12C22 10.8954 21.1046 10 20 10H4Z"
          />
          <mask
            id="NavigationMenuIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={2}
            y={3}
            width={20}
            height={18}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2 5C2 3.89543 2.89543 3 4 3H20C21.1046 3 22 3.89543 22 5C22 6.10457 21.1046 7 20 7H4C2.89543 7 2 6.10457 2 5ZM2 19C2 17.8954 2.89543 17 4 17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19ZM4 10C2.89543 10 2 10.8954 2 12C2 13.1046 2.89543 14 4 14H20C21.1046 14 22 13.1046 22 12C22 10.8954 21.1046 10 20 10H4Z"
            />
          </mask>
          <g mask="url(#NavigationMenuIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
NavigationMenuIcon.nessiePropTypes = iconNessiePropTypes;
NavigationMenuIcon = nessiePropCheckerWrapper(NavigationMenuIcon);
export default NavigationMenuIcon;
