import * as React from "react";
import { RAW_cssValue, ThemeUIStyleObject } from "../../nessie/stylingLib";
import { CloseButton } from "../modals";
import { Tile } from "./Tile";
import HeadlineText from "../../nessie/components/typography/headlineText";

type WelcomeTileProps = {
  title?: React.ReactNode;
  image?: string;
  imageWidth?: string | number;
  close?: () => void;
  children: React.ReactNode;
  className?: string;
};

export const WelcomeTile = ({ title, image, imageWidth, close, children, className }: WelcomeTileProps) => {
  return (
    <Tile sx={{ ...containerStyle }} className={className}>
      {close && <CloseButton data-name="welcome_tile:close_button" close={close} />}
      <div sx={{ display: "flex" }}>
        {image && (
          <div>
            <img alt="" src={image} sx={{ width: imageWidth, marginRight: "dt_xl" }} />
          </div>
        )}
        <div sx={{ flex: 1 }}>
          {title && (
            <HeadlineText as="h2" sx={{ ...titleStyle, ...(close && { paddingRight: "dt_m" }) }}>
              {title}
            </HeadlineText>
          )}
          <div sx={contentStyle}>{children}</div>
        </div>
      </div>
    </Tile>
  );
};

const containerStyle: ThemeUIStyleObject = {
  position: "relative",
  padding: "dt_l",
};
const titleStyle: ThemeUIStyleObject = {
  fontSize: "1.8rem",
  fontWeight: 600,
  marginBottom: RAW_cssValue("1rem"),
};
const contentStyle: ThemeUIStyleObject = {
  fontSize: "1.4rem",
  fontWeight: 200,
};
