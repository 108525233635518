import { useEffect, useState } from "react";
import { T } from "../..";
import { CheckboxRadioGroup } from "../../nessie";
import { translate } from "../../utils/translate";

type DataTransferLegalSectionProps = {
  entityType?: "teacher" | "parent";
  setDataTransferConsentGranted: (arg: boolean) => void;
};

export default function DataTransferLegalSection({
  entityType,
  setDataTransferConsentGranted,
}: DataTransferLegalSectionProps): JSX.Element {
  const [hasApprovedDataTransfer, setHasApprovedDataTransfer] = useState(false);

  useEffect(() => {
    setDataTransferConsentGranted(hasApprovedDataTransfer);
    // don't include `setDataTransferConsentGranted` in effect dependencies so it
    // doesn't execute if the passed function reference changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasApprovedDataTransfer]);

  return (
    <CheckboxRadioGroup
      sx={{
        paddingBottom: "dt_s",
        display: "flex",
        borderRadius: "dt_radius_m",
        border: "dt_card",
        boxShadow: "dt_shadow_shadezies",
        marginBottom: "dt_xs",
        padding: "m",
        textAlign: "left",
        position: "relative",
      }}
      groupName="legal"
      groupType="checkbox"
      aria-label={translate({ str: "dojo.common:consent.legal_section", fallback: "Legal section" })}
      inputs={[
        {
          checked: hasApprovedDataTransfer,
          onChange: (e) => setHasApprovedDataTransfer(e.target.checked),
          labelText:
            entityType === "teacher" ? (
              <T
                str="dojo.common:safe_harbor.data_transfer"
                fallback="I agree ClassDojo can transfer information generated by my class's use of ClassDojo to the US, for the purpose of providing us with the service."
              />
            ) : (
              <T
                str="dojo.common:safe_harbor.family_data_transfer"
                fallback="I agree ClassDojo can transfer information generated by my family’s use of ClassDojo to the US, for the purpose of providing us with the service."
              />
            ),
        },
      ]}
    />
  );
}
