// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type LinkIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let LinkIcon: NessieFunctionComponent<LinkIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "LinkIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: LinkIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg width="1em" height="1em" viewBox="0 0 1024 1024" fill="currentColor" {...props}>
          <path
            fill="shale"
            d="M 329.746094 394.25 C 272.484375 394.25 226.0625 440.699219 226.0625 498 C 226.0625 555.300781 272.484375 601.75 329.746094 601.75 L 433.429688 601.75 C 456.332031 601.75 474.902344 620.332031 474.902344 643.25 C 474.902344 666.167969 456.332031 684.75 433.429688 684.75 L 329.746094 684.75 C 226.671875 684.75 143.117188 601.136719 143.117188 498 C 143.117188 394.863281 226.671875 311.25 329.746094 311.25 L 433.429688 311.25 C 456.332031 311.25 474.902344 329.832031 474.902344 352.75 C 474.902344 375.667969 456.332031 394.25 433.429688 394.25 Z M 557.847656 352.75 C 557.847656 329.832031 576.414062 311.25 599.320312 311.25 L 703.003906 311.25 C 806.074219 311.25 889.632812 394.863281 889.632812 498 C 889.632812 601.136719 806.074219 684.75 703.003906 684.75 L 599.320312 684.75 C 576.414062 684.75 557.847656 666.167969 557.847656 643.25 C 557.847656 620.332031 576.414062 601.75 599.320312 601.75 L 703.003906 601.75 C 760.261719 601.75 806.683594 555.300781 806.683594 498 C 806.683594 440.699219 760.261719 394.25 703.003906 394.25 L 599.320312 394.25 C 576.414062 394.25 557.847656 375.667969 557.847656 352.75 Z M 557.847656 352.75 M 350.484375 498 C 350.484375 475.082031 369.046875 456.5 391.957031 456.5 C 391.957031 456.5 617.886719 456.5 640.792969 456.5 C 663.699219 456.5 682.265625 475.082031 682.265625 498 C 682.265625 520.917969 663.699219 539.5 640.792969 539.5 C 617.886719 539.5 391.957031 539.5 391.957031 539.5 C 369.046875 539.5 350.484375 520.917969 350.484375 498 Z M 350.484375 498"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
LinkIcon.nessiePropTypes = iconNessiePropTypes;
LinkIcon = nessiePropCheckerWrapper(LinkIcon);
export default LinkIcon;
