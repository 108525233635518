import { ThemeUIStyleObject } from "../../stylingLib";
import { BaseText, BaseTextProps } from "./baseText";

export function OverlineText(props: BaseTextProps) {
  return <BaseText sx={overlineTextStyles} {...props} />;
}

export default OverlineText;

// eslint-disable-next-line react-refresh/only-export-components
export const overlineTextStyles: ThemeUIStyleObject = {
  fontSize: "12px",
  lineHeight: "18px",
  fontWeight: "dt_bold",
  letterSpacing: "0.4px",
};
