// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type LockIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let LockIcon: NessieFunctionComponent<LockIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "LockIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: LockIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3472 9.82901H16.4514V6.40138C16.4514 3.98646 14.4649 2 12.05 2C9.63508 2 7.64862 3.98646 7.64862 6.40138V9.82901H6.75276C5.77901 9.82901 5 10.608 5 11.5818L5.77901 19.7224C5.77901 20.6961 6.55801 21.4751 7.53177 21.4751H16.5682C17.542 21.4751 18.321 20.6961 18.321 19.7224L19.1 11.5818C19.1 10.647 18.321 9.82901 17.3472 9.82901ZM12.9848 18.6318H11.1541L10.8036 15.7105C10.8036 15.0094 11.3878 14.4251 12.089 14.4251C12.7901 14.4251 13.3743 15.0094 13.3743 15.7105L12.9848 18.6318ZM14.5818 9.82901H9.51823V6.40138C9.51823 4.99917 10.6478 3.86961 12.05 3.86961C13.4522 3.86961 14.5818 4.99917 14.5818 6.40138V9.82901Z"
          />
          <mask
            id="LockIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={5}
            y={2}
            width={15}
            height={20}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.3472 9.82901H16.4514V6.40138C16.4514 3.98646 14.4649 2 12.05 2C9.63508 2 7.64862 3.98646 7.64862 6.40138V9.82901H6.75276C5.77901 9.82901 5 10.608 5 11.5818L5.77901 19.7224C5.77901 20.6961 6.55801 21.4751 7.53177 21.4751H16.5682C17.542 21.4751 18.321 20.6961 18.321 19.7224L19.1 11.5818C19.1 10.647 18.321 9.82901 17.3472 9.82901ZM12.9848 18.6318H11.1541L10.8036 15.7105C10.8036 15.0094 11.3878 14.4251 12.089 14.4251C12.7901 14.4251 13.3743 15.0094 13.3743 15.7105L12.9848 18.6318ZM14.5818 9.82901H9.51823V6.40138C9.51823 4.99917 10.6478 3.86961 12.05 3.86961C13.4522 3.86961 14.5818 4.99917 14.5818 6.40138V9.82901Z"
            />
          </mask>
          <g mask="url(#LockIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
LockIcon.nessiePropTypes = iconNessiePropTypes;
LockIcon = nessiePropCheckerWrapper(LockIcon);
export default LockIcon;
