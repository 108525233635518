import groupBy from "lodash/groupBy";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import { createRef, Component } from "react";
import { type HighchartsReactRefObject } from "highcharts-react-official";
import { ThemeUIStyleObject } from "../../../nessie/stylingLib";
import { isAnimationEnabled } from "../../../utils/animationEnabledState";
import { isTesting } from "../../../utils/env";
import { translate } from "../../../utils/translate";
import T from "../T";
import { Highchart } from "../Highchart";

const awardsChecksum = (arr: Award[]) =>
  arr
    .map((x) => x._id)
    .sort()
    .join("");
const isZero = (x: Award) => x.weight === 0;
const isPositive = (x: Award) => !!x.positive && x.weight !== 0;
const isNegative = (x: Award) => !x.positive && x.weight !== 0;
const sumByWeight = (arr: Award[]) => arr.reduce((a, x) => a + x.weight, 0);
const countNeutral = (arr: Award[]) => arr.filter(isZero).length;
const countPositive = (arr: Award[]) => arr.filter(isPositive).length;
const countNegative = (arr: Award[]) => arr.filter(isNegative).length;
const sumPositive = (arr: Award[]) => sumByWeight(arr.filter(isPositive));
const sumNegative = (arr: Award[]) => Math.abs(sumByWeight(arr.filter(isNegative)));

type Award = {
  _id: string;
  positive?: boolean;
  weight: number;
  name: string;
};

type AwardPieChartProps = {
  countMode: boolean;
  awards: Award[];
};

// TODO: It's not clear if we still need all this or if we can just memo the component,
// the chart series clear out doesn't seem necessary with the latest high charts libraries
// eslint-disable-next-line react-prefer-function-component/react-prefer-function-component
export default class AwardPieChart extends Component<AwardPieChartProps> {
  shouldComponentUpdate(nextProps: AwardPieChartProps): boolean {
    return (
      this.props.countMode !== nextProps.countMode ||
      awardsChecksum(this.props.awards) !== awardsChecksum(nextProps.awards)
    );
  }

  componentDidUpdate(): void {
    const chart = this.chartRef.current?.chart;

    if (chart) {
      while (chart.series.length) {
        chart.series[chart.series.length - 1].remove();
      }
      if (!isTesting) {
        chart.addSeries(this._createInnerPieConfig());
        chart.addSeries(this._createOuterPieConfig());
      }
    }
  }

  render() {
    const series = [this._createInnerPieConfig(), this._createOuterPieConfig()];
    const config = { ...chartConfig, series };

    const { positive, negative, neutral, total } = this.props.countMode
      ? this._calculateCounts()
      : this._calculateSums();

    const percentPositive = `${Math.round((100 * positive) / (total || 1))}%`;

    return (
      <div data-name="awardPieChart" sx={styles.chart}>
        <div sx={chartValuesStyles}>
          <T str="dojo.common:donut.positive_with_count" subs={{ count: positive }} />
          <T str="dojo.common:donut.needs_work_with_count" subs={{ count: negative }} />
          {neutral > 0 && <T str="dojo.common:donut.neutral_with_count" subs={{ count: neutral }} />}
        </div>

        <Highchart options={config} ref={this.chartRef} />
        <div sx={styles.percentPositiveWrapper}>
          <p sx={styles.percentPositiveNumber}>{percentPositive}</p>
          <span sx={styles.percentPositiveText}>
            <T str="dojo.common:donut.positive" fallback="Positive" />
          </span>
        </div>
      </div>
    );
  }

  chartRef = createRef<HighchartsReactRefObject>();

  _calculateSums() {
    const { awards } = this.props;
    const positive = sumPositive(awards);
    const negative = sumNegative(awards);
    const neutral = countNeutral(awards); // count, not sum, since sum of 0s is 0
    return { positive, negative, neutral, total: positive + negative };
  }

  _calculateCounts() {
    const { awards } = this.props;
    const positive = countPositive(awards);
    const negative = countNegative(awards);
    const neutral = countNeutral(awards);
    return { positive, negative, neutral, total: awards.length };
  }

  _createInnerPieConfig(): Highcharts.SeriesPieOptions {
    const { positive, negative, neutral } = this.props.countMode ? this._calculateCounts() : this._calculateSums();

    const positiveSummary = {
      name: translate("dojo.common:donut.positive"),
      y: positive,
      color: "#5d5",
    };

    const negativeSummary = {
      name: translate("dojo.common:donut.needs_work"),
      y: negative,
      color: "#d55",
    };

    const neutralSummary = {
      name: translate("dojo.common:donut.neutral"),
      y: neutral,
      color: "#aaa",
    };

    const data = this.props.countMode
      ? [negativeSummary, neutralSummary, positiveSummary]
      : [negativeSummary, positiveSummary];

    return {
      type: "pie",
      name: this.props.countMode
        ? translate("dojo.common:donut.count", "Count")
        : translate("dojo.common:donut.score", "Score"),
      size: "33%",
      innerSize: "89%",
      borderRadius: 0,
      dataLabels: {
        enabled: false,
      },
      data,
    };
  }

  _createOuterPieConfig(): Highcharts.SeriesPieOptions {
    const { awards, countMode } = this.props;

    const index = {
      positive: 0,
      negative: 0,
      neutral: 0,
    };

    const colors = {
      positive: "#00ff00",
      negative: "#ff0000",
      neutral: "#aaaaaa",
    };

    function transformPoint(behaviorPoints: Award[]) {
      const name = behaviorPoints[0].name;
      const type = behaviorPoints[0].weight === 0 ? "neutral" : behaviorPoints[0].weight > 0 ? "positive" : "negative";
      const weight = behaviorPoints[0].weight;
      const y = countMode ? behaviorPoints.length : Math.abs(sumByWeight(behaviorPoints));

      const colorScale = [0.25, 0, 0.25, 0.65];
      const color = shade(-1 * colorScale[index[type]++ % colorScale.length], colors[type]);
      return { name, y, color, weight };
    }

    const behaviors = sortBy(map(groupBy(awards, "name"), transformPoint), "weight");

    return {
      type: "pie",
      name: this.props.countMode
        ? translate("dojo.common:donut.count", "Count")
        : translate("dojo.common:donut.score", "Score"),
      data: behaviors,
      innerSize: "46%",
      size: "80%",
      borderRadius: 0,
    };
  }
}

const chartConfig: Highcharts.Options = {
  chart: {
    animation: isAnimationEnabled(),
    plotBackgroundColor: undefined,
    plotBorderWidth: undefined,
    plotShadow: false,
    marginTop: 20,
    height: 400,
    // Disable resizing, we should consider allowing this, but trying to maintain the same functionality for now
    reflow: false,
    style: {
      fontSize: "110%",
    },
  },
  exporting: {
    enabled: false,
  },
  title: {
    text: undefined,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      animation: isAnimationEnabled(),
      states: {
        hover: {
          enabled: false,
        },
      },
    },
    pie: {
      cursor: "default",
      dataLabels: {
        enabled: true,
        color: "#545382",
        connectorColor: "#545382",
        style: {
          fontWeight: "regular",
          color: "#545382",
          lineHeight: "1.4rem",
          textShadow: "none",
        },
        formatter() {
          const limit = 15;
          return this.point.name?.length >= limit ? `${this.point.name.slice(0, limit)}...` : this.point.name;
        },
      },
    },
  },
};

const styles: Record<string, ThemeUIStyleObject> = {
  percentPositiveNumber: {
    padding: 0,
    margin: 0,
    fontWeight: 700,
    fontSize: "30px",
  },

  percentPositiveText: {
    color: "dt_content_tertiary",
    fontSize: "1.6rem",
  },

  percentPositiveWrapper: {
    position: "absolute",
    left: 0,
    right: 0,
    textAlign: "center",
    top: "47%",
  },

  chart: {
    position: "relative",
    minWidth: "30rem",
    margin: "auto",
  },
};

const chartValuesStyles: ThemeUIStyleObject = {
  display: "flex",
  justifyContent: "center",
  color: "dt_content_secondary",
  "&>:not(:last-child)": { marginRight: "dt_s" },
};

function shade(p: number, c0: string) {
  const n = p < 0 ? p * -1 : p;
  const f = parseInt(c0.slice(1), 16);
  const t = parseInt((p < 0 ? "#000000" : "#FFFFFF").slice(1), 16);
  const R1 = f >> 16;
  const G1 = (f >> 8) & 0x00ff;
  const B1 = f & 0x0000ff;
  return `#${(
    0x1000000 +
    (Math.round(((t >> 16) - R1) * n) + R1) * 0x10000 +
    (Math.round((((t >> 8) & 0x00ff) - G1) * n) + G1) * 0x100 +
    (Math.round(((t & 0x0000ff) - B1) * n) + B1)
  )
    .toString(16)
    .slice(1)}`;
}
