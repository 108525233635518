// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type NotificationIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let NotificationIcon: NessieFunctionComponent<NotificationIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "NotificationIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: NotificationIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.8012 20.8848H10.1988C10.0614 20.8848 9.95028 20.9918 9.95028 21.1255C9.95028 22.1593 10.8703 23 11.9994 23C13.1297 23 14.0497 22.1593 14.0497 21.1255C14.0497 20.9918 13.9386 20.8848 13.8012 20.8848ZM18.0189 15.467C18.2268 14.8879 18.332 14.2926 18.332 13.6914V8.81902C18.332 6.334 16.6186 4.16528 14.0497 3.36873V2.87684C14.0497 1.8419 13.1297 1 11.9994 1C10.8703 1 9.95028 1.8419 9.95028 2.87684V3.36757C7.38141 4.16528 5.66923 6.334 5.66923 8.81902V13.6914C5.66923 14.2949 5.77438 14.8903 5.98228 15.467C4.28682 15.5566 3 16.3973 3 17.4544V19.2079C3 19.3405 3.11112 19.4487 3.24852 19.4487H20.7515C20.8889 19.4487 21 19.3405 21 19.2079V17.4544C21 16.3973 19.7132 15.5566 18.0189 15.467Z"
          />
          <mask
            id="NotificationIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={3}
            y={1}
            width={18}
            height={22}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.8012 20.8848H10.1988C10.0614 20.8848 9.95028 20.9918 9.95028 21.1255C9.95028 22.1593 10.8703 23 11.9994 23C13.1297 23 14.0497 22.1593 14.0497 21.1255C14.0497 20.9918 13.9386 20.8848 13.8012 20.8848ZM18.0189 15.467C18.2268 14.8879 18.332 14.2926 18.332 13.6914V8.81902C18.332 6.334 16.6186 4.16528 14.0497 3.36873V2.87684C14.0497 1.8419 13.1297 1 11.9994 1C10.8703 1 9.95028 1.8419 9.95028 2.87684V3.36757C7.38141 4.16528 5.66923 6.334 5.66923 8.81902V13.6914C5.66923 14.2949 5.77438 14.8903 5.98228 15.467C4.28682 15.5566 3 16.3973 3 17.4544V19.2079C3 19.3405 3.11112 19.4487 3.24852 19.4487H20.7515C20.8889 19.4487 21 19.3405 21 19.2079V17.4544C21 16.3973 19.7132 15.5566 18.0189 15.467Z"
            />
          </mask>
          <g mask="url(#NotificationIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
NotificationIcon.nessiePropTypes = iconNessiePropTypes;
NotificationIcon = nessiePropCheckerWrapper(NotificationIcon);
export default NotificationIcon;
