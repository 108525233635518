// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type InviteIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let InviteIcon: NessieFunctionComponent<InviteIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "InviteIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: InviteIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.26 9.74C15.03 10.614 14.662 11.35 14.202 11.994C12.454 13.282 11.304 15.352 11.304 17.652C11.304 19.032 11.718 20.274 12.408 21.378H1.736C1.322 21.378 1 21.056 1 20.642V18.066C1 17.744 1.184 17.468 1.46 17.376C4.726 16.088 8.36 14.11 8.682 13.144V12.546C8.038 11.856 7.486 10.89 7.164 9.74C6.75 9.418 6.474 8.912 6.474 8.36C6.474 7.946 6.612 7.578 6.842 7.302V5.002C6.842 2.61 8.268 1 11.212 1C14.248 1 15.582 2.61 15.582 5.002V7.302C15.812 7.578 15.95 7.946 15.95 8.36C15.95 8.958 15.674 9.464 15.26 9.74ZM12.868 17.744C12.868 14.662 15.352 12.178 18.434 12.178C21.516 12.178 24 14.662 24 17.744C24 20.826 21.516 23.31 18.434 23.31C15.352 23.31 12.868 20.826 12.868 17.744ZM19.124 18.434H20.964C21.286 18.434 21.608 18.112 21.608 17.79C21.608 17.422 21.332 17.146 20.964 17.146H19.124V15.306C19.124 14.938 18.848 14.662 18.48 14.662C18.112 14.662 17.836 14.938 17.836 15.306V17.146H15.996C15.628 17.146 15.352 17.422 15.352 17.79C15.352 18.158 15.628 18.434 15.996 18.434H17.836V20.274C17.836 20.642 18.112 20.918 18.48 20.918C18.848 20.918 19.124 20.642 19.124 20.274V18.434Z"
          />
          <mask
            id="InviteIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={1}
            y={1}
            width={23}
            height={23}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.26 9.74C15.03 10.614 14.662 11.35 14.202 11.994C12.454 13.282 11.304 15.352 11.304 17.652C11.304 19.032 11.718 20.274 12.408 21.378H1.736C1.322 21.378 1 21.056 1 20.642V18.066C1 17.744 1.184 17.468 1.46 17.376C4.726 16.088 8.36 14.11 8.682 13.144V12.546C8.038 11.856 7.486 10.89 7.164 9.74C6.75 9.418 6.474 8.912 6.474 8.36C6.474 7.946 6.612 7.578 6.842 7.302V5.002C6.842 2.61 8.268 1 11.212 1C14.248 1 15.582 2.61 15.582 5.002V7.302C15.812 7.578 15.95 7.946 15.95 8.36C15.95 8.958 15.674 9.464 15.26 9.74ZM12.868 17.744C12.868 14.662 15.352 12.178 18.434 12.178C21.516 12.178 24 14.662 24 17.744C24 20.826 21.516 23.31 18.434 23.31C15.352 23.31 12.868 20.826 12.868 17.744ZM19.124 18.434H20.964C21.286 18.434 21.608 18.112 21.608 17.79C21.608 17.422 21.332 17.146 20.964 17.146H19.124V15.306C19.124 14.938 18.848 14.662 18.48 14.662C18.112 14.662 17.836 14.938 17.836 15.306V17.146H15.996C15.628 17.146 15.352 17.422 15.352 17.79C15.352 18.158 15.628 18.434 15.996 18.434H17.836V20.274C17.836 20.642 18.112 20.918 18.48 20.918C18.848 20.918 19.124 20.642 19.124 20.274V18.434Z"
            />
          </mask>
          <g mask="url(#InviteIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
InviteIcon.nessiePropTypes = iconNessiePropTypes;
InviteIcon = nessiePropCheckerWrapper(InviteIcon);
export default InviteIcon;
