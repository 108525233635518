// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type PhotoLibraryIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let PhotoLibraryIcon: NessieFunctionComponent<PhotoLibraryIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "PhotoLibraryIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: PhotoLibraryIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.8493 6.34117C22.7154 6.15836 22.5368 6.06696 22.3582 6.02126L20.3491 5.70135L19.9919 3.64478C19.9026 3.23347 19.5008 2.95926 19.0989 3.00497L11.6875 4.42171L5.92796 3.55338C5.52613 3.50768 5.1243 3.78189 5.07966 4.1932L4.85642 5.65565L1.64181 6.24976C1.46322 6.29547 1.28463 6.38687 1.15068 6.56967C1.01674 6.75248 0.972095 6.93528 1.01674 7.16379L3.29376 19.9602C3.33841 20.143 3.4277 20.3258 3.60629 20.4629C3.74023 20.5543 3.87418 20.6 4.05277 20.6H4.18671L14.411 18.6805L20.4384 19.5946H20.5277C20.8848 19.5946 21.242 19.3204 21.2867 18.909L21.5099 17.3095L21.6438 17.2638C22.0457 17.1724 22.3135 16.7611 22.2689 16.3498L21.9117 14.476L22.9833 6.79818C23.0279 6.75248 22.9833 6.52397 22.8493 6.34117ZM21.0634 9.81447L20.3937 14.6588L20.1705 16.1213L19.9472 17.7665L19.9026 18.0407L19.0543 17.9036L14.3217 17.2181L4.90107 15.8471L6.10654 7.16379L6.32978 5.51854L6.37443 5.33574L6.9102 5.42714L11.6428 6.11266L19.0096 7.20949L20.6169 7.438L21.376 7.5294L21.0634 9.81447ZM18.6078 4.74162L18.7418 5.51854L16.3754 5.15293L18.6078 4.74162ZM2.62405 7.75791L4.58853 7.3923L3.69559 13.6991L2.62405 7.75791ZM4.63318 19.0005L4.32065 17.2638L9.63369 18.0407L4.63318 19.0005ZM9.76763 10.7742C10.5813 10.7742 11.241 10.099 11.241 9.26606C11.241 8.43313 10.5813 7.75791 9.76763 7.75791C8.95391 7.75791 8.29427 8.43313 8.29427 9.26606C8.29427 10.099 8.95391 10.7742 9.76763 10.7742ZM16.7326 13.2421L18.1167 11.7796L18.965 16.2584L19.0543 16.8068L17.4023 16.5783L6.10654 14.933C7.31202 11.8253 11.6875 13.6077 11.6875 13.6077L14.9914 9.72307L16.7326 13.2421Z"
          />
          <mask
            id="PhotoLibraryIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={1}
            y={3}
            width={22}
            height={18}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.8493 6.34117C22.7154 6.15836 22.5368 6.06696 22.3582 6.02126L20.3491 5.70135L19.9919 3.64478C19.9026 3.23347 19.5008 2.95926 19.0989 3.00497L11.6875 4.42171L5.92796 3.55338C5.52613 3.50768 5.1243 3.78189 5.07966 4.1932L4.85642 5.65565L1.64181 6.24976C1.46322 6.29547 1.28463 6.38687 1.15068 6.56967C1.01674 6.75248 0.972095 6.93528 1.01674 7.16379L3.29376 19.9602C3.33841 20.143 3.4277 20.3258 3.60629 20.4629C3.74023 20.5543 3.87418 20.6 4.05277 20.6H4.18671L14.411 18.6805L20.4384 19.5946H20.5277C20.8848 19.5946 21.242 19.3204 21.2867 18.909L21.5099 17.3095L21.6438 17.2638C22.0457 17.1724 22.3135 16.7611 22.2689 16.3498L21.9117 14.476L22.9833 6.79818C23.0279 6.75248 22.9833 6.52397 22.8493 6.34117ZM21.0634 9.81447L20.3937 14.6588L20.1705 16.1213L19.9472 17.7665L19.9026 18.0407L19.0543 17.9036L14.3217 17.2181L4.90107 15.8471L6.10654 7.16379L6.32978 5.51854L6.37443 5.33574L6.9102 5.42714L11.6428 6.11266L19.0096 7.20949L20.6169 7.438L21.376 7.5294L21.0634 9.81447ZM18.6078 4.74162L18.7418 5.51854L16.3754 5.15293L18.6078 4.74162ZM2.62405 7.75791L4.58853 7.3923L3.69559 13.6991L2.62405 7.75791ZM4.63318 19.0005L4.32065 17.2638L9.63369 18.0407L4.63318 19.0005ZM9.76763 10.7742C10.5813 10.7742 11.241 10.099 11.241 9.26606C11.241 8.43313 10.5813 7.75791 9.76763 7.75791C8.95391 7.75791 8.29427 8.43313 8.29427 9.26606C8.29427 10.099 8.95391 10.7742 9.76763 10.7742ZM16.7326 13.2421L18.1167 11.7796L18.965 16.2584L19.0543 16.8068L17.4023 16.5783L6.10654 14.933C7.31202 11.8253 11.6875 13.6077 11.6875 13.6077L14.9914 9.72307L16.7326 13.2421Z"
            />
          </mask>
          <g mask="url(#PhotoLibraryIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
PhotoLibraryIcon.nessiePropTypes = iconNessiePropTypes;
PhotoLibraryIcon = nessiePropCheckerWrapper(PhotoLibraryIcon);
export default PhotoLibraryIcon;
