import * as React from "react";
import { NessieThemeColors } from "../../../nessie/components/theme";
import { ThemeUIStyleObject } from "../../../nessie/stylingLib";

const sizes = {
  mini: {
    svgSize: 20,
    fontSize: undefined,
  },
  small: {
    svgSize: 44,
    fontSize: "12px",
  },
  medium: {
    svgSize: 70,
    fontSize: "14px",
  },
  regular: {
    svgSize: 120,
    fontSize: "2.4rem",
  },
};

export type GaugeProps = {
  value?: number;
  size?: keyof typeof sizes;
  color?: NessieThemeColors;
  content?: React.ReactNode;
  hidePercent?: boolean;
};

function Gauge({
  value = 0,
  size = "regular",
  color = "dt_content_success",
  hidePercent = false,
  content,
}: GaugeProps) {
  const normalizedValue = normalizeValue(value);
  const showValue = !hidePercent && size !== "mini";

  return (
    <div sx={{ position: "relative" }}>
      <div sx={gaugeLabelStyle}>
        <div sx={{ fontSize: sizes[size].fontSize, fontWeight: 600, color }}>{showValue && `${normalizedValue}%`}</div>
        {size === "regular" || size === "medium" ? content : null}
      </div>

      <SvgGauge percent={value} size={size} color={color} />
    </div>
  );
}

function normalizeValue(value: number) {
  if (isNaN(value)) return 0;
  else if (value > 100) return 100;
  else if (value < 0) return 0;
  else return Math.round(value);
}

export default Gauge;

const gaugeLabelStyle: ThemeUIStyleObject = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  zIndex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const SvgGauge = ({
  percent = 0,
  size,
  color,
}: {
  percent: number;
  size: keyof typeof sizes;
  color: NessieThemeColors;
}) => {
  const { svgSize } = sizes[size];
  const radius = 50;
  const strokeWidth = radius * 0.25;
  const innerRadius = radius - strokeWidth;
  const circumference = innerRadius * 2 * Math.PI;
  const dashArray = `${circumference} ${circumference}`;
  const offset = circumference - (percent / 100) * circumference;
  return (
    <div sx={{ margin: "auto", width: svgSize, height: svgSize }}>
      <svg
        width="100%"
        height={"100%"}
        viewBox={"0 0 100 100"}
        sx={{
          "circle:first-of-type": { stroke: "dt_background_tertiary" },
          "circle:last-of-type": { stroke: color },
        }}
      >
        <circle cx={radius} cy={radius} fill="transparent" r={innerRadius} strokeWidth={strokeWidth} />
        <circle
          cx={radius}
          cy={radius}
          fill="transparent"
          r={innerRadius}
          strokeDasharray={dashArray}
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          transform={`rotate(270, ${radius}, ${radius})`}
          strokeDashoffset={offset}
          sx={{ transition: "stroke-dashoffset 0.3s" }}
        />
      </svg>
    </div>
  );
};
