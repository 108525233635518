// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type RepeatIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let RepeatIcon: NessieFunctionComponent<RepeatIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "RepeatIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: RepeatIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7001 7.99998V9.49118C13.7001 9.9048 14.1737 10.1396 14.5029 9.8891L17.7771 7.39781C18.0401 7.19771 18.0401 6.80208 17.7771 6.60198L14.5029 4.11069C14.1737 3.86024 13.7001 4.09499 13.7001 4.50861V5.99998L6.55 5.99998C5.3517 5.99998 4.41885 6.42152 3.80182 7.14461C3.20927 7.83899 3 8.70311 3 9.44997V13C3 13.5523 3.44772 14 4 14C4.55229 14 5 13.5523 5 13L5 9.44997C5 9.04683 5.11573 8.68596 5.32319 8.44285C5.50615 8.22844 5.8483 7.99998 6.55 7.99998L13.7001 7.99998ZM10.2999 14.5088V16L17.45 16C18.1517 16 18.4939 15.7715 18.6768 15.5571C18.8843 15.314 19 14.9531 19 14.55V11C19 10.4477 19.4477 9.99997 20 9.99997C20.5523 9.99997 21 10.4477 21 11V14.55C21 15.2968 20.7907 16.161 20.1982 16.8553C19.5812 17.5784 18.6483 18 17.45 18L10.2999 18V19.4913C10.2999 19.905 9.82632 20.1397 9.49715 19.8893L6.22288 17.398C5.95989 17.1979 5.95989 16.8022 6.22288 16.6021L9.49715 14.1108C9.82632 13.8604 10.2999 14.0951 10.2999 14.5088Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
RepeatIcon.nessiePropTypes = iconNessiePropTypes;
RepeatIcon = nessiePropCheckerWrapper(RepeatIcon);
export default RepeatIcon;
