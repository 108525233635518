// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type TimerIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let TimerIcon: NessieFunctionComponent<TimerIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "TimerIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: TimerIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.17025 3.88064L4.10025 6.44064C3.68025 6.79064 3.05025 6.74064 2.70025 6.31064C2.34025 5.89064 2.40025 5.26064 2.82025 4.90064L5.90025 2.34064C6.32025 1.99064 6.95025 2.04064 7.30025 2.47064C7.66025 2.89064 7.60025 3.52064 7.17025 3.88064ZM21.1803 4.90067L18.1003 2.34067C17.6803 1.99067 17.0503 2.04067 16.6903 2.47067C16.3403 2.89067 16.4003 3.52067 16.8203 3.88067L19.8903 6.44067C20.3103 6.79067 20.9403 6.74067 21.3003 6.31067C21.6603 5.89067 21.6003 5.26067 21.1803 4.90067ZM5.00024 13C5.00024 9.13401 8.13425 6 12.0002 6C15.8662 6 19.0002 9.13401 19.0002 13C19.0002 16.866 15.8662 20 12.0002 20C8.13425 20 5.00024 16.866 5.00024 13ZM12.0002 4C7.02968 4 3.00024 8.02944 3.00024 13C3.00024 17.9706 7.02968 22 12.0002 22C16.9708 22 21.0002 17.9706 21.0002 13C21.0002 8.02944 16.9708 4 12.0002 4ZM12.6002 9.2C12.6002 8.64772 12.1525 8.2 11.6002 8.2C11.048 8.2 10.6002 8.64772 10.6002 9.2V13.4C10.6002 13.7344 10.7673 14.0466 11.0455 14.232L14.0455 16.232C14.5051 16.5384 15.1259 16.4142 15.4323 15.9547C15.7386 15.4952 15.6145 14.8743 15.1549 14.5679L12.6002 12.8648V9.2Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
TimerIcon.nessiePropTypes = iconNessiePropTypes;
TimerIcon = nessiePropCheckerWrapper(TimerIcon);
export default TimerIcon;
