import { lazy, Suspense } from "react";
import type { Props as EmojiPickerProps } from "@classdojo/emoji-picker-react";
import LoadingMojo from "../LoadingMojo";
import styles from "./EmojiPicker.module.css";

export const LazyEmojiPicker = lazy(() => import("@classdojo/emoji-picker-react"));

const Loading = ({ width }: { width?: number | string }) => {
  return (
    <aside className={styles.loadingContainer} sx={{ width }}>
      <LoadingMojo />
    </aside>
  );
};

export const EmojiPicker = (props: EmojiPickerProps) => {
  return (
    <Suspense fallback={<Loading width={props.width} />}>
      <LazyEmojiPicker {...props} />
    </Suspense>
  );
};
