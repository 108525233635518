// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type NewChatIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let NewChatIcon: NessieFunctionComponent<NewChatIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "NewChatIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: NewChatIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9.29153 3.19995C7.04272 3.19995 6.59985 3.19995 5.59942 3.19995C4.27394 3.19995 3.19922 4.27447 3.19922 5.59995V18.4C3.19922 19.7254 4.27374 20.8 5.59922 20.8H18.3992C19.7247 20.8 20.7992 19.7254 20.7992 18.4V12"
            stroke="#2C2A50"
            strokeWidth={3}
            strokeLinecap="round"
            fill="transparent"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.654 0.942529L23.0624 3.34975C23.1588 3.44604 23.223 3.57442 23.1909 3.70281C23.1909 3.8312 23.1588 3.95958 23.0624 4.05587L23.0303 4.08797L21.5593 5.55822L18.4607 2.45959L18.8237 2.098L18.8558 2.0659L19.6265 1.29559L19.9797 0.942529C20.1724 0.749951 20.4614 0.749951 20.654 0.942529ZM16.9579 3.95678L8.96536 11.9195C8.93325 11.9516 8.86903 12.0157 8.86903 12.0799L7.23133 16.1562C7.16711 16.3487 7.19922 16.5413 7.32767 16.6697C7.424 16.766 7.55245 16.7981 7.68089 16.7981C7.74512 16.7981 7.80934 16.7981 7.87357 16.766L10.4425 15.7389L11.9196 15.1291C11.9839 15.097 12.0481 15.0649 12.0802 15.0328L20.059 7.05786L16.9579 3.95678Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
NewChatIcon.nessiePropTypes = iconNessiePropTypes;
NewChatIcon = nessiePropCheckerWrapper(NewChatIcon);
export default NewChatIcon;
