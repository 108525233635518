// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type TwitterIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let TwitterIcon: NessieFunctionComponent<TwitterIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "TwitterIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: TwitterIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          {...props}
        >
          <path
            d="m8.2896 20.126c7.5472 0 11.675-6.2528 11.675-11.675 0-0.1776 0-0.3544-0.012-0.5304a8.3488 8.3488 0 0 0 2.0472-2.124 8.1904 8.1904 0 0 1-2.3568 0.6456 4.1176 4.1176 0 0 0 1.804-2.2696 8.2232 8.2232 0 0 1-2.6056 0.996 4.1072 4.1072 0 0 0-6.9928 3.7424 11.65 11.65 0 0 1-8.4568-4.2872 4.1064 4.1064 0 0 0 1.2704 5.4776 4.0728 4.0728 0 0 1-1.8624-0.5136v0.052a4.1048 4.1048 0 0 0 3.292 4.0224 4.0968 4.0968 0 0 1-1.8528 0.0704 4.108 4.108 0 0 0 3.8336 2.8496 8.2336 8.2336 0 0 1-5.096 1.76 8.3528 8.3528 0 0 1-0.9768-0.0592 11.617 11.617 0 0 0 6.2896 1.84"
            strokeWidth={0.08}
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
TwitterIcon.nessiePropTypes = iconNessiePropTypes;
TwitterIcon = nessiePropCheckerWrapper(TwitterIcon);
export default TwitterIcon;
