// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type SearchIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let SearchIcon: NessieFunctionComponent<SearchIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "SearchIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: SearchIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6ZM3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.1928 14.1929C14.5833 13.8024 15.2165 13.8024 15.607 14.1929L20.007 18.5929C20.3975 18.9834 20.3975 19.6166 20.007 20.0071C19.6165 20.3977 18.9833 20.3977 18.5928 20.0071L14.1928 15.6071C13.8023 15.2166 13.8023 14.5834 14.1928 14.1929Z"
          />
          <path d="M15.3072 17.8355C14.9167 17.445 14.9167 16.8118 15.3072 16.4213L16.4386 15.2899C16.8291 14.8994 17.4623 14.8994 17.8528 15.2899L21.7419 19.179C22.1324 19.5696 22.1324 20.2027 21.7419 20.5932L20.6105 21.7246C20.22 22.1151 19.5868 22.1151 19.1963 21.7246L15.3072 17.8355Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
SearchIcon.nessiePropTypes = iconNessiePropTypes;
SearchIcon = nessiePropCheckerWrapper(SearchIcon);
export default SearchIcon;
