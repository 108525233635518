// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type ResetIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let ResetIcon: NessieFunctionComponent<ResetIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "ResetIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: ResetIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9998 5C9.37002 5 6.38553 7.76267 6.03432 11.3H7.9024C8.33125 11.3 8.56111 11.8045 8.27971 12.1281L5.37709 15.4661C5.17777 15.6953 4.8218 15.6953 4.62248 15.4661L1.71986 12.1281C1.43846 11.8045 1.66831 11.3 2.09716 11.3H4.02658C4.38379 6.65657 8.26475 3 12.9998 3C17.9703 3 21.9998 7.02944 21.9998 12C21.9998 16.9706 17.9703 21 12.9998 21C10.5148 21 8.26338 19.9915 6.6358 18.364C6.24527 17.9734 6.24527 17.3403 6.6358 16.9497C7.02632 16.5592 7.65948 16.5592 8.05001 16.9497C9.31785 18.2176 11.0665 19 12.9998 19C16.8657 19 19.9998 15.866 19.9998 12C19.9998 8.13401 16.8657 5 12.9998 5ZM13.5999 8.20001C13.5999 7.64773 13.1521 7.20001 12.5999 7.20001C12.0476 7.20001 11.5999 7.64773 11.5999 8.20001V12.4C11.5999 12.7344 11.767 13.0466 12.0452 13.2321L15.0452 15.2321C15.5047 15.5384 16.1256 15.4142 16.4319 14.9547C16.7383 14.4952 16.6141 13.8743 16.1546 13.568L13.5999 11.8648V8.20001Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
ResetIcon.nessiePropTypes = iconNessiePropTypes;
ResetIcon = nessiePropCheckerWrapper(ResetIcon);
export default ResetIcon;
