import { PureComponent } from "react";
import * as React from "react";
import { theme } from "../theme";
import StackingContext from "./StackingContext";

type StackProps = {
  value: (typeof theme.stackingOrder)[keyof typeof theme.stackingOrder];
  children: (value: number) => React.ReactNode;
};

// eslint-disable-next-line react-prefer-function-component/react-prefer-function-component
export default class Stack extends PureComponent<StackProps> {
  render() {
    const { children, value } = this.props;
    return (
      <StackingContext.Consumer>
        {(previousValue) => {
          const currentValue = Math.max(value, previousValue);
          const nextValue = currentValue + 1;
          return <StackingContext.Provider value={nextValue}>{children(currentValue)}</StackingContext.Provider>;
        }}
      </StackingContext.Consumer>
    );
  }
}
