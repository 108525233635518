import { format as formatDate, DateFormatOptions } from "@web-monorepo/dates";
import { fromNow } from "../../utils/time";

type PrettyDateProps = {
  date: Date;
  format?: DateFormatOptions;
};

const PrettyDate = ({ date, format }: PrettyDateProps): JSX.Element => {
  const momentDate = date;
  return (
    <span>
      {format ? formatDate(momentDate, /* TODO: Update this moment format string: */ format) : fromNow(momentDate)}
    </span>
  );
};

export default PrettyDate;
