import { useState } from "react";
import { ThemeUIStyleObject } from "../../../nessie/stylingLib";
import { handleActionKeyDown } from "../../../utils/keyboard";
import { translate } from "../../../utils/translate";
import WithClickOutside from "../WithClickOutside";
import Icon from "./Icon";
import styles from "./Styles";

type IconOption = {
  value: number | string;
  iconUrl: string;
};

const toggleContainerWidth = 90;

export type IconPickerProps = {
  value: number | string;
  onChange: (value: number | string) => void;
  options: IconOption[];
  tabIndex?: number;
};

/**
 * Icon picker
 */
const IconPicker = ({ value, onChange, options, tabIndex = 0 }: IconPickerProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const item = options.find((opt) => String(opt.value) === String(value));
  const src = item && item.iconUrl;

  const closePicker = () => setIsOpen(false);
  const togglePicker = () => setIsOpen(!isOpen);

  const onSelect = (selected: IconOption) => {
    onChange(selected.value);
    togglePicker();
  };
  const picker = (
    <div
      data-name="iconPickerContent"
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(5, 1fr)`,
        gap: "dt_xxs",
        ...pickerContainer,
      }}
    >
      {options.map((icon, i) => (
        <Icon key={i} icon={icon} onSelect={() => onSelect(icon)} aria-label={`behavior icon ${icon.value}`} />
      ))}
    </div>
  );

  const onKeyDown = handleActionKeyDown({ onAction: togglePicker, onCancel: closePicker });

  return (
    <div sx={{ position: "relative" as const, width: toggleContainerWidth }}>
      <WithClickOutside onClickOutside={closePicker}>
        <div
          data-name="iconPickerToggle"
          sx={toggleContainer}
          onClick={togglePicker}
          tabIndex={tabIndex}
          onKeyDown={onKeyDown}
          // eslint-disable-next-line @web-monorepo/no-jsx-role-button
          role="button"
          aria-expanded={isOpen}
          aria-label={translate({ str: "dojo.common:actions.select_icon", fallback: "Select an icon" })}
        >
          <img alt="" data-name="iconPickerCurrentIcon" sx={styles.toggleImage} src={src} />
          <span sx={styles.caret} />
        </div>
        {isOpen && picker}
      </WithClickOutside>
    </div>
  );
};

export default IconPicker;

const toggleContainer: ThemeUIStyleObject = {
  backgroundColor: "dt_background_secondary",
  border: "dt_card",
  borderRadius: "dt_radius_s",
  textAlign: "center",
  padding: "s",
  width: toggleContainerWidth,
  height: toggleContainerWidth,
  cursor: "pointer",
};

const pickerContainer: ThemeUIStyleObject = {
  backgroundColor: "dt_background_primary",
  border: `dt_divider`,
  marginTop: "dt_xs",
  borderRadius: "dt_radius_s",
  position: "absolute",
  left: "50%",
  padding: "dt_m",
  transform: "translate(-50%, 0)",
  boxShadow: "dt_shadow_shadezies_small",
  zIndex: 120,
  overflowY: "auto",
  overflowX: "hidden",
};
