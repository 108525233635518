// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type MagicIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let MagicIcon: NessieFunctionComponent<MagicIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "MagicIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: MagicIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.27635 5.97374L6.6901 5.11124L7.5526 3.52499C7.8001 3.07124 7.63135 2.50124 7.1776 2.25374C6.7201 2.00624 6.15385 2.17499 5.90635 2.62874L5.04385 4.21499L3.4576 3.35249C3.0001 3.10499 2.43385 3.27374 2.18635 3.72749C1.93885 4.18124 2.1076 4.75124 2.56135 4.99874L4.1476 5.86124L3.2851 7.44749C3.0376 7.90124 3.20635 8.47124 3.6601 8.71874C3.8026 8.79749 3.95635 8.83124 4.10635 8.83124C4.4401 8.83124 4.7626 8.65499 4.93135 8.33999L5.79385 6.75374L7.3801 7.61624C7.5226 7.69499 7.67635 7.72874 7.82635 7.72874C8.1601 7.72874 8.4826 7.55249 8.65135 7.23749C8.89885 6.78374 8.7301 6.21374 8.27635 5.96624V5.97374ZM8.00632 20.5987L7.07257 16.3012C6.95632 15.765 6.30382 15.5587 5.89882 15.9262L2.64757 18.885C2.24257 19.2525 2.38882 19.92 2.91007 20.0887L7.09882 21.4275C7.62007 21.5925 8.12632 21.135 8.01007 20.5987H8.00632ZM18.0152 16.4738L20.4939 17.46H20.4977C21.3527 17.7975 22.1102 16.8 21.5514 16.0688L19.9277 13.95C19.2039 13.005 19.2077 11.6925 19.9389 10.755L21.7127 8.475C22.2789 7.74375 21.5139 6.73125 20.6552 7.08L18.2927 8.04375C17.1227 8.52 15.7802 8.0925 15.1014 7.0275L13.5377 4.57875C13.0352 3.7875 11.8089 4.18875 11.8727 5.12625L12.0564 7.82625C12.1464 9.1275 11.2614 10.2975 9.98268 10.5638L7.33518 11.1188C6.41642 11.31 6.36393 12.6 7.26018 12.87L10.0427 13.71C11.2539 14.0738 12.0302 15.2513 11.8877 16.5075L11.6027 19.0425C11.4977 19.9613 12.6789 20.4263 13.2302 19.68L14.9439 17.3513C15.6489 16.395 16.9089 16.035 18.0152 16.4738Z"
            fill="black"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
MagicIcon.nessiePropTypes = iconNessiePropTypes;
MagicIcon = nessiePropCheckerWrapper(MagicIcon);
export default MagicIcon;
