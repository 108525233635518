// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type RewardIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let RewardIcon: NessieFunctionComponent<RewardIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "RewardIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: RewardIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0005 14.8351L10.0617 15.9093C9.53085 16.2034 8.86254 15.9607 8.64409 15.3945L7.8324 13.2907L5.64769 12.8405C5.06213 12.7198 4.7126 12.1157 4.89982 11.5479L5.61462 9.3802L4.21273 7.58963C3.8461 7.12134 3.96675 6.43849 4.47166 6.1242L6.38485 4.93329L6.44116 2.659C6.45607 2.05653 6.99654 1.60404 7.59225 1.69528L9.78269 2.03078L11.245 0.344777C11.6438 -0.114926 12.3572 -0.114926 12.7559 0.344777L14.2182 2.03078L16.4087 1.69528C17.0044 1.60404 17.5449 2.05653 17.5598 2.659L17.6161 4.93329L19.5293 6.1242C20.0342 6.43849 20.1548 7.12134 19.7882 7.58963L18.3863 9.3802L19.1011 11.5479C19.2883 12.1157 18.9388 12.7198 18.3532 12.8405L16.1685 13.2907L15.3568 15.3945C15.1384 15.9607 14.4701 16.2034 13.9392 15.9093L12.0005 14.8351ZM12.0005 11.3257C13.6778 11.3257 15.0375 9.94148 15.0375 8.23393C15.0375 6.52637 13.6778 5.14212 12.0005 5.14212C10.3231 5.14212 8.9634 6.52637 8.9634 8.23393C8.9634 9.94148 10.3231 11.3257 12.0005 11.3257ZM10.7903 17.2659C9.56257 17.9461 8.02564 17.4843 7.35748 16.2345C7.3074 16.1408 7.26309 16.044 7.22479 15.9448L6.72156 14.6405L5.93643 22.9029C5.86165 23.6898 6.6864 24.2483 7.38934 23.8867L11.5433 21.7501C11.8302 21.6026 12.1707 21.6025 12.4577 21.75L16.6169 23.8873C17.32 24.2486 18.1446 23.6897 18.0694 22.9028L17.2803 14.6392L17.2798 14.6393L16.7761 15.9448C16.2651 17.2692 14.7962 17.9212 13.4951 17.401C13.3976 17.362 13.3026 17.3169 13.2106 17.2659L12.0004 16.5954L10.7903 17.2659Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
RewardIcon.nessiePropTypes = iconNessiePropTypes;
RewardIcon = nessiePropCheckerWrapper(RewardIcon);
export default RewardIcon;
