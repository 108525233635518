// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type DojoIslandsIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let DojoIslandsIcon: NessieFunctionComponent<DojoIslandsIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "DojoIslandsIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: DojoIslandsIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M21.6073 7.67227C20.5611 6.84352 18.0561 5.60227 16.1286 6.13102C15.4423 6.31852 14.8723 6.58102 14.4111 6.90352C14.2011 7.04977 13.9198 6.95977 13.8336 6.71602C13.6461 6.18352 13.3386 5.63602 12.8961 5.07727C11.6473 3.51727 8.8873 3.04852 7.55605 3.10852C7.26355 3.11977 7.08355 3.56601 7.2748 3.80226L7.49605 3.92227C8.34355 4.39102 9.1198 4.99101 9.81355 5.69976C9.92605 5.81601 9.8473 6.01102 9.6823 6.01477C8.01355 6.05227 6.03355 6.91852 5.03605 7.55602C4.7623 7.73227 4.75855 8.19727 5.02855 8.28727L5.28355 8.26477C7.4773 8.06227 9.5773 8.57601 11.3848 9.72351C11.5611 9.83601 11.6061 10.076 11.4861 10.2485C10.8898 11.1148 10.3423 12.2885 10.4511 14.066C10.4623 14.2423 10.3573 14.4035 10.1923 14.471C9.18355 14.8648 8.3023 15.5173 7.6348 16.346C7.53355 16.3348 7.4323 16.3273 7.3273 16.3273C6.00355 16.3273 4.90855 17.2948 4.70605 18.5585C4.66855 18.7835 4.8523 18.986 5.08105 18.986H5.9548C6.17605 18.986 6.35605 19.1773 6.32605 19.3985C6.3073 19.5373 6.2923 19.6798 6.2848 19.8223C6.2698 20.0323 6.4498 20.2085 6.6598 20.2085H14.4373C14.6173 20.2085 14.7561 20.366 14.7298 20.546C14.7298 20.546 14.7298 20.5498 14.7298 20.5535C14.7073 20.7335 14.8461 20.8948 15.0261 20.8948H19.6123C19.8373 20.8948 20.0211 20.6923 19.9873 20.471C19.8523 19.6273 19.3161 18.9185 18.5848 18.5398C18.4161 18.4535 18.2998 18.2998 18.2361 18.1198C17.6211 16.4135 16.2748 15.056 14.5723 14.4298C14.4148 14.3735 14.3098 14.2273 14.3023 14.0623C14.2723 12.9973 14.1823 12.131 13.9198 11.261C13.8448 11.0098 14.0398 10.766 14.3023 10.7773C16.4848 10.8785 18.7311 11.7035 20.4261 13.1848L20.6211 13.3535C20.8948 13.4435 21.1648 13.061 21.0448 12.7573C20.6098 11.6585 19.5186 9.79478 18.1911 8.78228C18.0636 8.68478 18.1086 8.47852 18.2698 8.44852C19.2486 8.28352 20.2273 8.25351 21.1873 8.36976L21.4348 8.39977C21.7273 8.32102 21.8436 7.85602 21.6148 7.67227H21.6073Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
DojoIslandsIcon.nessiePropTypes = iconNessiePropTypes;
DojoIslandsIcon = nessiePropCheckerWrapper(DojoIslandsIcon);
export default DojoIslandsIcon;
