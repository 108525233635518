// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type MessagesIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let MessagesIcon: NessieFunctionComponent<MessagesIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "MessagesIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: MessagesIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 20"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M12 12H6V15.9867C6 16.9073 7.11736 17.3491 7.7348 16.6726L12 12Z" />
          <path d="M0 6.5C0 2.91015 2.91015 0 6.5 0H11.5C15.0899 0 18 2.91015 18 6.5C18 10.0899 15.0899 13 11.5 13L6.5 12.75C2.5 12.75 0 10.0899 0 6.5Z" />
          <path d="M19.9796 7.09589C22.2721 7.55118 24.0002 9.57378 24.0002 12C24.0002 14.419 22.2824 16.4367 20.0002 16.9V19.1894C20.0002 19.9258 19.0691 20.2792 18.5545 19.7381L15.951 17H15.0002C13.6238 17 12.3773 16.4438 11.4731 15.544L11.982 14.9866C16.2547 14.7478 19.6848 11.3534 19.9796 7.09589Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
MessagesIcon.nessiePropTypes = iconNessiePropTypes;
MessagesIcon = nessiePropCheckerWrapper(MessagesIcon);
export default MessagesIcon;
